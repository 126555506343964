import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import { useNavigation } from "@react-navigation/native";
import { theme } from "../../../Global/Theme";
import MenuMobile from '../MenuMobile/MenuMobile';

const url = 'https://i.ibb.co/dgQ0FrM/logo-loterural-cabeca.png';
const urlVenda = 'https://i.ibb.co/sVXTF82/icone-venda.png';
const urlQuemSomos = 'https://i.ibb.co/cN9qykP/icone-quem-somos.png';
const urlParceiros = 'https://i.ibb.co/xs0tXys/icone-parceiros.png';
const urlCadastro = 'https://i.ibb.co/xz5BjMx/icone-cadastro.png';
const urlRemate = 'https://i.ibb.co/XDLWqPP/icone-remates.png';
const urlHome = 'https://i.ibb.co/y51wpws/icone-home.png';
const urlFaleConosco = 'https://i.ibb.co/FbbrhYy/icone-fale-conosco.png';
const urlGaleria = 'https://i.ibb.co/WD5CPPy/icone-galeria.png';

const ImageButton = styled(ButtonBase)({
  position: 'relative',
  backgroundColor: theme.colors.primary,
  height: 60,
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '0px solid currentColor',
    },
  },
});

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 5,
  bottom: 0,
  backgroundSize: '90px 50px',
  backgroundPosition: 'center 40%',
});

const Image = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.colors.primary,
});

const ImageMarked = styled('span')({
  height: '5px',
  width: '150px',
  backgroundColor: theme.colors.tertiary,
  borderRadius: 12,
  position: 'absolute',
  top: 0,
  left: 0,
});

export default function ButtonBasesPrincipal(tag) {
  const { innerWidth: width, innerHeight: height } = window;
  const navigation = useNavigation<any>();
  const handleQuemSomos = () => {
    navigation.navigate("QuemSomos")
  };

  const handleParceiros = () => {
    navigation.navigate("Parceiros")
  };
  const handleGaleria = () => {
    navigation.navigate('Galeria de Fotos')
    
  };

  const larguraPercent = () => {    
    if (innerWidth <  640) {
      return '90%'
    } else {
      return '60%'
    }
  };
  
  const fontAdapt = () => {    
    if (innerWidth <  640) {
      return '9px'
    } else {
      return '13px'
    }
  };
  
  return (
    <>
      {innerWidth > 640 ? (
        <>
          <ImageButton
            focusRipple
            key={tag}
            style={{
              width: '150px',
              margin: 3,
              borderRadius: 5,
            }}
          >
            <ImageSrc 
              style={{
                backgroundImage: `url(${urlHome})`,
                backgroundRepeat: 'no-repeat'
              }} 
            >        
            <Image onClick={() => navigation.navigate("Inicio")}>
              <Typography
                component="span"
                fontSize={fontAdapt()}
                variant="overline"
                color="inherit"
                sx={{
                  fontWeight: 'bold', 
                  color: theme.colors.secondary,
                  position: 'relative',
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                HOME
              </Typography>
            </Image>
            </ImageSrc> 

          </ImageButton>

          <ImageButton
            focusRipple
            key={tag}        
            style={{
              width: '150px',
              margin: 3,
              borderRadius: 5,
            }}
          >
            <ImageSrc 
              style={{
                backgroundImage: `url(${urlRemate})`,
                backgroundRepeat: 'no-repeat'
              }} 
            >        
            <Image onClick={() => navigation.navigate('Remates')}>
              <Typography
                component="span"
                fontSize={fontAdapt()}
                variant="overline"
                color="inherit"
                sx={{
                  fontWeight: 'bold', 
                  color: theme.colors.secondary,
                  position: 'relative',
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                REMATES

              </Typography>
            </Image>
            </ImageSrc> 
          </ImageButton>

          <ImageButton
            focusRipple
            key={tag}
            style={{
              width: '150px',
              margin: 3,
              borderRadius: 5,
            }}
          >
            <ImageSrc 
              style={{
                backgroundImage: `url(${urlVenda})`,
                backgroundRepeat: 'no-repeat'
              }} 
            >        
            <Image onClick={() => navigation.navigate("Captacao")}>
              <Typography
                component="span"
                fontSize={fontAdapt()}
                variant="overline"
                color="inherit"
                sx={{
                  fontWeight: 'bold', 
                  color: theme.colors.secondary,
                  position: 'relative',
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                VENDA CONOSCO

              </Typography>
            </Image>
            </ImageSrc> 
          </ImageButton>

          <ImageButton
            focusRipple
            key={tag}
            style={{
              width: '150px',
              margin: 3,
              borderRadius: 5,
            }}
          >
            <ImageSrc 
              style={{
                backgroundImage: `url(${urlCadastro})`,
                backgroundRepeat: 'no-repeat'
              }} 
            >    
            <Image onClick={() => navigation.navigate("Cadastrar")}>
              <Typography
                component="span"
                variant="overline"
                fontSize={fontAdapt()}
                color="inherit"
                sx={{
                  fontWeight: 'bold', 
                  color: theme.colors.secondary,
                  position: 'relative',
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                CADASTRO

              </Typography>
            </Image>
            </ImageSrc> 
          </ImageButton>

          <ImageButton
            focusRipple
            key={tag}
            style={{
              width: '150px',
              margin: 3,
              borderRadius: 5,
            }}
          >
            <ImageSrc 
              style={{
                backgroundImage: `url(${urlQuemSomos})`,
                backgroundRepeat: 'no-repeat'
              }} 
            >        
            <Image onClick={handleQuemSomos}>
              <Typography
                component="span"
                variant="overline"
                fontSize={fontAdapt()}
                color="inherit"
                sx={{
                  fontWeight: 'bold', 
                  color: theme.colors.secondary,
                  position: 'relative',
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                EMPRESA

              </Typography>
            </Image>
            </ImageSrc> 
          </ImageButton>

          <ImageButton
            focusRipple
            key={tag}
            style={{
              width: '150px',
              margin: 3,
              borderRadius: 5,
            }}
          >
            <ImageSrc 
              style={{
                backgroundImage: `url(${urlParceiros})`,
                backgroundRepeat: 'no-repeat'
              }} 
            >        
            <Image onClick={handleParceiros}>
              <Typography
                component="span"
                variant="overline"
                fontSize={fontAdapt()}
                color="inherit"
                sx={{
                  fontWeight: 'bold', 
                  color: theme.colors.secondary,
                  position: 'relative',
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                PARCEIROS

              </Typography>
            </Image>
            </ImageSrc> 
          </ImageButton>

          <ImageButton
            focusRipple
            key={tag}
            style={{
              width: '150px',
              margin: 3,
              borderRadius: 5,
            }}
          >
            <ImageSrc 
              style={{
                backgroundImage: `url(${urlGaleria})`,
                backgroundRepeat: 'no-repeat'
              }} 
            >        
            <Image onClick={handleGaleria}>
              <Typography
                component="span"
                variant="overline"
                fontSize={fontAdapt()}
                color="inherit"
                sx={{
                  fontWeight: 'bold', 
                  color: theme.colors.secondary,
                  position: 'relative',
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                GALERIA

              </Typography>
            </Image>
            </ImageSrc> 
          </ImageButton>

          <ImageButton
            focusRipple
            key={tag}
            style={{
              width: '150px',
              margin: 3,
              borderRadius: 5,
            }}
          >
            <ImageSrc 
              style={{
                backgroundImage: `url(${urlFaleConosco})`,
                backgroundRepeat: 'no-repeat'
              }} 
            >        
            <Image onClick={() => navigation.navigate("FaleConosco")}>
              <Typography
                component="span"
                fontSize={fontAdapt()}
                variant="overline"
                color="inherit"
                sx={{
                  fontWeight: 'bold', 
                  color: theme.colors.secondary,
                  position: 'relative',
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                CONTATO

              </Typography>
            </Image>
            </ImageSrc> 
          </ImageButton>
      </>
    ) : 
    (<> 
    <MenuMobile tag="principal"></MenuMobile>
    </>)}
    </>
  )
}
