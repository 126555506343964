import { View } from "react-native";
import {
  StyleSheet,
  Alert,
} from "react-native";
import React, { useState } from "react";
import { TextInput, Text, Button } from "react-native-paper";
import { useNavigation, useRoute } from "@react-navigation/native";
import { updatePassword } from "../../../Services/api/AuthServices";
import { theme } from "../../../Global/Theme";
import Menu from "../../../components/Gerais/Menu/Menu";
import HeaderBotton from "../../../components/HeaderBottom/HeaderBottom";
import { ScrollView } from "react-native-gesture-handler";

const ChangePassword = (props) => {
  const [password, setPassword] = useState("");
  const [new_password, setnew_Password] = useState("");
  const [loading, setLoading] = useState(false);
  const route = useRoute();
  const navigation = useNavigation<any>();
  const handleSignInScreens = () => {
    navigation.navigate("SignInScreens")
  };

  function handleTwoPassword(id, old_Password, new_Password) {
    let valida = false;
    if (old_Password != new_Password) {
      valida = false
      Alert.alert('Senha não são identicas.Corriga por favor')

    }
    else {
      valida = true
    }
    return valida;
  }

  async function handleUpdatePassword(id: string, newPassword: string) {
    try {
      setLoading(true);
      await updatePassword(id, newPassword);
      setLoading(false);
      handleSignInScreens;
    } catch (error) {
      Alert.alert("Erro", "Não foi possivel atualizar a senha");
      setLoading(false);
    }
  }
  return (
    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <Menu location="Login"></Menu>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}
      >
        <Text
          style={{ textAlign: "center", marginTop: 10, color: theme.colors.primary }}
          variant="titleLarge"
        >
          Alterar a Senha
        </Text>
        <TextInput
          style={styles.textInput}
          mode="outlined"
          label="Nova Senha"
          secureTextEntry
          value={password}
          onChangeText={(value) => setPassword(value)}
        />
        <TextInput
          style={styles.textInput}
          mode="outlined"
          label="Repita a Senha"
          onBlur={value => handleTwoPassword(props.id, password, value)}
          secureTextEntry
          value={new_password}
          onChangeText={(value) => setnew_Password(value)}
        />
        <Button
          mode="contained"
          loading={loading}
          style={styles.buttonInput}
          onPress={() => handleUpdatePassword(props.id, password)}
        >
          Salvar Nova Senha
        </Button>
      </ScrollView>
      <HeaderBotton />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // paddingTop: 20,
    // paddingLeft: 10,
    // paddingRight: 10,
    // paddingBottom: 20,
    marginTop: 0,
    flex: 1,
    justifyContent: "flex-start",
  },
  title: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
  },
  textInput: { marginLeft: 10, marginRight: 10, marginTop: 10 },
  imageTop: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    width: "100%",
    height: 250,
  },
  buttonInput: { marginLeft: 10, marginRight: 10, marginTop: 20 },
  error: {
    color: "red",
    fontSize: 16,
  },
});

export default ChangePassword;
