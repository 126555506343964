import React from "react";
import HeaderBotton from "../../components/HeaderBottom/HeaderBottom";
import Menu from "../../components/Gerais/Menu/Menu";
import { CardMedia, Grid, Typography, styled } from "@mui/material";
import Paper from '@mui/material/Paper';
import { theme } from "../../Global/Theme";
import { Appbar, IconButton } from "react-native-paper";
import CardContato from "../../components/Cards/CardContato/CardContato";
import { ScrollView } from "react-native-gesture-handler";
import { View } from "react-native";

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  borderRadius: '12px',
  maxWidth: '100%',
  maxHeight: '100%',
});

export default function ContactScreen() {  
 
  const larguraPercent = () => { 
    if (innerWidth <  640) {
      return 'column'
    } else {
      return 'row'
    }
  };
  

  return (
    <View style={{ flex: 1, backgroundColor: theme.colors.primary }}>
      <Menu location='history' />
      <CardMedia
            style={{ 
        //      backgroundSize: 'cover', 
        //      backgroundPosition: 'center',
        //      backgroundRepeat: 'no-repeat',              
              position: 'relative',
              width: innerWidth < 640 ? ('100%') : ('40%'),              
              marginLeft: 'auto',
              marginRight: 'auto'                            
            }}                
            component="img"
            alt="Agenda Remates"            
            height={250}
            image="https://i.ibb.co/NmyLQmP/foto-contato.jpg"
          />       
      <Appbar
        style={{
          height: '70px',
          width: '100%',
          backgroundColor: theme.colors.primary,
          justifyContent: "flex-start",
          alignItems: "center",
        }}>
        <IconButton
          icon="circle"
          iconColor={theme.colors.tertiary}
          size={10}
        />
        <Typography color={theme.colors.secondary}>NOSSA EQUIPE ESTA A DISPOSIÇÃO PARA ATENDE-LOS</Typography>
      </Appbar>            
      <ScrollView 
        contentContainerStyle={{
          paddingBottom: 20,        
          backgroundColor: theme.colors.primary,          
      }}>

      <Paper
        sx={{
          p: 2,
          height: '30%',
          flexGrow: 1,
          backgroundColor: theme.colors.primary,
        }}
      >
            
        <Grid container spacing={2}>
          <Grid item container>
            <Grid xs container direction={larguraPercent()}>
              <Grid xs >
                <CardContato title={"ARTHUR FREITAS"} contact={"(55) 99627.7432"} position={"Leiloeiro Rural"} WhatsApp />
                <CardContato title={"JOAO PEDRO ALVES"} contact={"(55) 99513.8016"} position={"Comercial"} WhatsApp />           
                <CardContato title={"DR HOMERO TARRAGÔ"} contact={"(55) 99974.1064"} position={"Leiloeiro Rural"} WhatsApp />                                
                
              </Grid>            
              <Grid xs >
                <CardContato title={"MITIARLO COELHO (CURTO)"} contact={"(55) 99962.6661"} position={"Comercial"} WhatsApp/>
                <CardContato title={"AGENDA REMATES"} contact={"(55) 99987.7095"} position={"Cadastros/Financeiro"} WhatsApp/>
                <CardContato title={"AGENDA REMATES"} contact={"(55) 99973.7473"} position={"Contra Notas"} WhatsApp/>                                
                
              </Grid>                          
              
            </Grid>            
          </Grid>
        </Grid>                        
      </Paper>
      </ScrollView>
      <HeaderBotton></HeaderBotton>        
    </View>
  );
}
