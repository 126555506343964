
import { theme } from "../../Global/Theme";
import { Text } from "react-native-paper";
import { View } from 'react-native';

export default function HeaderBotton() {
  return (
    <View style={{
      position: 'absolute',
      bottom: 0,
      backgroundColor: theme.colors.tertiary,
      width: '100%',
      height: '30px',
    }}>
      <Text style={{
        color: theme.colors.primary,
        fontWeight: 'bold',
        fontStyle: 'italic',
        margin: 'auto',
        textAlignVertical: 'center',
        textAlign: 'center'
      }}>
        Rua Nossa Senhora do Carmo, 80 - Centro - Alegrete/RS
      </Text>
    </View>
  )
}
