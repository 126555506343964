import axios from "axios";

const API_BASE_URL =
  "https://proxy.cors.sh/http://ec2-3-20-102-1.us-east-2.compute.amazonaws.com:8444/api/";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 5000,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",    
    "Accept": "application/json",
    "x-cors-api-key" : "temp_597abf54bc2ee02b28d4a20eeaabf9fa"
  },
});

export default axiosInstance;
