import { View, Text, Alert, Linking, Image } from "react-native";
import React, { useContext, useEffect, useState } from "react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { ProductsStackParamList } from "../../Routes/routes";
import {
  Avatar,
  Button,
  Card,
  Paragraph,
  Appbar,
  Provider,
  Portal,
  Modal,
  ActivityIndicator,
  IconButton,
  Tooltip,
} from "react-native-paper";
import { ScrollView } from "react-native-gesture-handler";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import { Lote, Leilao } from "../../utils/types/Leilao.type";
import { getLeilao, setLance } from "../../Services/api/AuthServices";
import { useNavigation } from "@react-navigation/native";
import { theme } from "../../Global/Theme";
import Menu from "../../components/Gerais/Menu/Menu";
import { CardActions, Grid, Paper, Typography } from "@mui/material";
import styled from "@emotion/styled";
import moment from "moment";

type Props = NativeStackScreenProps<ProductsStackParamList, "SaleScreen">;

export default function SaleScreen({ route }: Props) {
  const navigation = useNavigation();
  const localizacao = window.location.href;
  const urlID = localizacao.substring(localizacao.indexOf('/leilao/'));
  const urlFinal = urlID.replace('/leilao/', '');
  console.log(urlFinal);
  const { leilao } = route.params;
  const { setState, state } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [currentSale, setCurrentSale] = useState<Lote | null>();
  const [multiplo, setMultiplo] = useState(null);
  const [lanceFinal, setLanceFinal] = useState(0);
  const [errorModal, setErrorModal] = useState(false);
  const [errorModalMsg, setErrorModalMsg] = useState("");
  const [loadingSubmition, setLoadingSubmition] = useState(false);
  const [loadingLeilao, setLoadingLeilao] = useState(true);
  const [currentLeilao, setCurrentLeiao] = useState<Leilao | null>();
  const [errorFetch, setErrorFetch] = useState(true);


  const PDF_Regulamento = "https://drive.google.com/file/d/1CNG-N-PvKVW_gTpQwEPWPp7TPEB2h15v/view";
  
  const hideModal = () => {
    setCurrentSale(null);
    setMultiplo("0");
    setVisible(false);
  };

  const containerStyle = {
    backgroundColor: "white",
    padding: 15,
    height: 300,
    borderRadius: 12,
  };

  const handleComentario = (comentario) => {
      return <Typography>{comentario}</Typography>
  };

  const LeftContent = (props) => (
    <Avatar.Image
      {...props}
      style={{ 
        backgroundColor: theme.colors.primary, 
      }}
      size={50}
      source={{ uri: "https://i.ibb.co/cg0tzRz/icone-agenda-dourado.png" }}
    />
  );

  const RightContent = (props) => (
    <Tooltip title="REGULAMENTO">
    <IconButton
      icon="note-alert"
      color={theme.colors.tertiary}
      {...props}
      size={50}
      onPress={() => {
        Linking.openURL(PDF_Regulamento);
      }}
    />
    </Tooltip>
  );
  
  function handleShowDialog(lote: Lote) {
    setCurrentSale(lote);
    setMultiplo(lote.MULTIPLO);
    setVisible(true);
  }

  function changeLance(text: string) {
    let newText = 0;
    if (text === "max") {
      newText = currentSale.LANMAX - currentSale.LANATU;
    } else {
      newText = parseFloat(text);
    }

    if (!isNaN(Number(text)) && !isNaN(parseInt(text))) {
      setMultiplo(parseInt(text));
      setLanceFinal(currentSale.LANATU + parseInt(text));
      return;
    } else if (text === "") {
      setMultiplo(null);
      setLanceFinal(currentSale.LANATU);
    }
  }
  async function handleSubmit(lote: Lote) {
    try {
      setLoadingSubmition(true);
      if (lanceFinal > currentSale.LANMAX && currentSale.VENDIDO === "S") {
        setErrorModal(true);
        setErrorModalMsg("Lote Vendido.");
        setLoadingSubmition(false);
        return;
      } else {
        const data = {
          id: 0,
          idlote: `${lote.ID}`,
          datalance: new Date().toISOString(),
          idcliente: `${state.user.id}`,
          ip: "192.168.0.1",
          origem: "APP",
          valor: lanceFinal,
        };
        await setLance(data);
        setVisible(false);
        setLoadingSubmition(false);
        Alert.alert("Lance", "Lance Efetuado com Sucesso");
        setErrorModal(true);
        await fetchLeilao(`${leilao.ID}`);
        //navigation.goBack();
        // setErrorModalMsg("Lance Efetuado com Sucesso");
        return;
      }
      //setErrorModal(false);

      // const data = {
      //   id: 0,
      //   idlote: lote.ID,
      //   datalance: new Date(),
      //   idcliente: state.user.id,
      //   ip: "192.168.0.1",
      //   origem: "APP",
      //   valor: lanceFinal,
      // };
      // console.log("data", data);
    } catch (error) {
      setLoadingSubmition(false);
      setErrorModalMsg(`${error}`);
      setErrorModal(true);
    }
  }

  async function fetchLeilao(id: string) {
    try {
      setLoadingLeilao(true);
      const data = await getLeilao(id);
      //console.log("fetchLeilao", JSON.stringify(data));
      setCurrentLeiao(data[0]);
      setLoadingLeilao(false);
      setErrorFetch(false);
    } catch (e) {
      //console.log("fetchLeilao err", e);
      setLoadingLeilao(false);
      setErrorFetch(true);
    }
  }

  useEffect(() => {
    (async () => {
      await fetchLeilao(leilao.ID != undefined ? `${leilao.ID}` : urlFinal);      
    })();
  }, []);

  const ButtonVendido = (props) => (
    <Button
      disabled
      theme={{ roundness: 0 }}
      icon="checkbox-multiple-marked-circle"
      style={{
        backgroundColor: state.loggedIn ? "red" : "red",
        marginTop: 10,
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
      }}
      labelStyle={{ color: "white" }}
      mode="contained"
    >
      Vendido
    </Button>
  );

  const ButtonLance = (props) => (
    <Button
      disabled={!state.loggedIn}
      theme={{ roundness: 0 }}
      icon="piggy-bank-outline"
      style={{
        backgroundColor: state.loggedIn ? "#182d1c" : "silver",
        marginTop: 10,
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
      }}
      labelStyle={{ color: "white" }}
      mode="contained"
      onPress={() => handleShowDialog(props.plote)}
    >
      {!state.loggedIn ? "Faça login para dar lance" : "Dar Lance"}
    </Button>
  );

  const Item = styled(Paper)(({
    width: '300px',
    height: '460px',
    margin: 4,
    textAlign: 'center',
    backgroundColor: theme.colors.primary,
  }));

  const descricaoLote = (descricao) => {
    return <Typography>{descricao}</Typography>
  }

  return (
    <View style={{ flex: 1, backgroundColor: theme.colors.primary }}>
      <Provider>
        {loadingLeilao ? (
          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ActivityIndicator animating={true} color="white" />
            <Text style={{ color: theme.colors.secondary }}>Buscando {leilao.LEILAO}</Text>
          </View>
        ) : errorFetch ? (
          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text>Erro ao buscar Leilão</Text>
          </View>
        ) : (
          <>
            <Menu location="SalesScreen" />
            <ScrollView
              showsVerticalScrollIndicator={false}
              contentInset={{ bottom: 100 }}
              contentContainerStyle={{ paddingBottom: 100 }
              }
            >
              <Card.Title
                theme={theme}
                titleStyle={{ color: theme.colors.secondary, fontWeight: 'bold', marginLeft: '10px' }}
                title={"Leilão: " + currentLeilao.LEILAO}
                subtitleStyle={{ color: theme.colors.secondary, fontWeight: 'bold', marginLeft: '10px' }}
                subtitle={"Data: " + moment(currentLeilao.DATLEI).format('DD/MM/YYYY')}
                left={LeftContent}
                right={RightContent}
              />
              <Card.Content style={{ marginBottom: 20 }}>
                <Paragraph style={{ color: theme.colors.secondary, fontWeight: 'bold' }}>{"Início: " + currentLeilao.HORA_INICIO}</Paragraph>
                <Paragraph style={{ color: theme.colors.secondary, fontWeight: 'bold' }}>{"Condições: " + currentLeilao.DESFIN}</Paragraph>
                <Paragraph style={{ color: theme.colors.secondary, fontWeight: 'bold' }}>{currentLeilao.OBSERVACOES}</Paragraph>
              </Card.Content>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}
                alignItems="center"
                justifyContent="center">

                {currentLeilao.Lotes.map((lote) => (
                  <Item>
                    <Card
                      theme={theme}
                      style={{ margin: 5, borderRadius: 12, }}
                      key={lote.ID}
                    >
                      <Card.Title
                        titleStyle={{ color: 'black', fontWeight: 'bold' }}
                        title={"Lote: " + lote.LOTEXX}
                        subtitleStyle={{ color: 'black', fontWeight: 'bold' }}
                        subtitle={descricaoLote(lote.DESLOT)}
                        right={() => (
                          <IconButton
                            icon="youtube"
                            iconColor={theme.colors.tertiary}
                            size={20}
                            onPress={() => Linking.openURL(lote.URLVIDEO)}
                          />
                        )}
                      ></Card.Title>

                      <Card.Cover
                        theme={{ roundness: 0 }}
                        style={{ margin: 5, borderRadius: 12 }}
                        source={{
                          uri:
                            "https://loterural.s3.us-east-2.amazonaws.com/lote_1_img_" +
                            lote.ID +
                            ".jpg",
                        }}
                      />

                      <Card.Content>
                        <CardActions disableSpacing>
                          <Tooltip title={lote.CATEGORIA}>
                            <Button icon="subtitles-outline" textColor={theme.colors.tertiary} mode="text">
                              Categoria
                            </Button>
                          </Tooltip>
                          <Tooltip title={lote.COMENTARIO}>
                            <Button 
                              icon="comment" 
                              textColor={theme.colors.tertiary} 
                              mode="text"
                              onPress={handleComentario}
                            >
                              Comentário
                            </Button>
                          </Tooltip>
                        </CardActions>
                        {                                                               }
                        <Paragraph style={{ color: "red", fontSize: 20, fontWeight: 'bold' }}>
                          {lote.LANATU
                            ? `Lance Atual: ${lote.LANATU?.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })}`
                            : "Lance Atual: R$ 0,00"}
                        </Paragraph>
                        <Paragraph
                          style={{ color: 'black', fontWeight: 'bold' }}>
                          {"Lance Máximo: " +
                            lote.LANMAX.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })}
                        </Paragraph>
                      </Card.Content>
                      {lote.VENDIDO === "S" ? (
                        <ButtonVendido />
                      ) : (
                        <ButtonLance plote={lote} />
                      )}
                    </Card>
                  </Item>
                ))}
              </Grid>              
            </ScrollView>
          </>
        )}
        <Portal>
          <Modal
            dismissable
            visible={visible}
            contentContainerStyle={containerStyle}
            style={{
              height: 600,
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <View
              style={{
                flex: 1,
              }}
            >
              <Text
                style={{
                  color: "red",
                  fontSize: 18,
                  fontWeight: "bold",
                  marginBottom: 5,
                  textAlign: "center",
                }}
              >
                Lance atual:{" "}
                {currentSale?.LANATU.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </Text>
              <Card.Actions>
                <Button
                  buttonColor="#182d1c"
                  labelStyle={{ color: "white" }}
                  key="20"
                  onPress={() => changeLance("20")}
                >
                  R$20
                </Button>
                <Button
                  buttonColor="#182d1c"
                  labelStyle={{ color: "white" }}
                  key="50"
                  onPress={() => changeLance("50")}
                >
                  R$50
                </Button>
                <Button
                  buttonColor="#182d1c"
                  labelStyle={{ color: "white" }}
                  key="100"
                  onPress={() => changeLance("100")}
                >
                  R$100
                </Button>
              </Card.Actions>
              <Card.Actions style={{ margin: 0 }}>
                <Button
                  buttonColor="silver"
                  labelStyle={{ color: "white" }}
                  key={currentSale?.LANMAX}
                  onPress={() =>
                    changeLance("max")
                  }
                >
                  Lance Máximo
                </Button>
              </Card.Actions>
              <Text
                style={{
                  color: "#182d1c",
                  fontSize: 18,
                  fontWeight: "bold",
                  marginBottom: 5,
                }}
              >
                Total do Lance: R$
                {lanceFinal.toFixed(2)}
              </Text>
              <Text
                style={{
                  color: "#182d1c",
                  fontSize: 18,
                  fontWeight: "bold",
                  marginBottom: 5,
                }}
              >
                Lance Máximo: R$
                {currentSale?.LANMAX.toFixed(2)}
              </Text>
              {errorModal && (
                <>
                  <Text style={{ color: "red" }}>{errorModalMsg}</Text>
                </>
              )}
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Button
                onPress={hideModal}
                theme={{ roundness: 0 }}
                labelStyle={{ color: "black" }}
                icon="cancel"
              >
                Cancelar
              </Button>
              <Button
                disabled={parseInt(multiplo) === 0}
                icon="checkbox-multiple-marked-circle"
                style={{ backgroundColor: "#182d1c", borderRadius: 12 }}
                labelStyle={{ color: "white" }}
                theme={{ roundness: 0 }}
                loading={loadingSubmition}
                mode="contained"
                onPress={() => handleSubmit(currentSale)}
              >
                Confirmar
              </Button>
            </View>
          </Modal>
        </Portal>
      </Provider>
    </View >
  );
}
