import React, { useState } from "react";
import {
  Alert,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { SignUpSchema } from "../../utils/YupSchema/SignUpSchema";
import TextInputForm from "../../components/TextInputForm/TextInputForm";
import { Button, Text } from "react-native-paper";
import { getCity, signUp } from "../../Services/api/AuthServices";
import { useNavigation } from "@react-navigation/native";
import { theme } from "../../Global/Theme";
import Menu from "../../components/Gerais/Menu/Menu";
import HeaderBotton from "../../components/HeaderBottom/HeaderBottom";
import { Typography } from "@mui/material";

export default function SignUpScreens() {
  const { innerWidth: width, innerHeight: height } = window;
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState('');
  const navigation = useNavigation<any>()
  const handlePress = () => {
    navigation.navigate("Login")
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignUpSchema),
  });
  const [tipoPessoa, setTipoPessoa] = useState('F');

  const larguraPercent = () => {    
    if (innerWidth <  640) {
      return '90%'
    } else {
      return '60%'
    }
  };
    

  async function handleUserRegister(values) {
    try {
      setLoading(true);

      const city = await getCity(values.cidade);
      console.log("values", city.id);
      values.cidade = parseInt(city.id);
      console.log("values", values.cidade);
      await signUp(values);
      setLoading(false);
      setInfo("Conta criada com sucesso")      
      
    return;
    } catch (error) {
      setLoading(false);
      setInfo("Desculpe!!!Tivemos problemas ao realizar o cadastramento, tente novamente")            
    }
  }
  return (

    <View style={{ backgroundColor: theme.colors.primary, flex: 1 }}>
      <Menu location="Cadastro" />
      <Text
          style={{ textAlign: "center", 
                    paddingTop: 5, 
                    marginLeft: 10, 
                    marginBottom: 25, 
                    color: theme.colors.secondary 
                }}
          variant="titleSmall"
        >
          FAÇA SEU CADATRO CONOSCO. ASSIM, TU PODES PARTICIPAR DE NOSSOS LEILÕES E FAZER COMPRAS PELO SITE OU APP DAS VENDAS DIRETAS
        </Text>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Button
            mode="contained"
            loading={loading}
            buttonColor={theme.colors.tertiary}
            style={{ width: '40vw', marginLeft: 10, marginBottom: 10 }}
            onPress={() => setTipoPessoa('F')}
          >
            PESSOA FISICA
          </Button>
          <Button
            mode="contained"
            loading={loading}
            buttonColor={theme.colors.secondary}
            textColor={theme.colors.tertiary}
            style={{ width: '40vw', marginLeft: 10, marginBottom: 10 }}
            onPress={() => setTipoPessoa('J')}
          >
            PESSOA JURIDICA
          </Button>
          </div>

        
      <ScrollView
        contentContainerStyle={{
          paddingBottom: 20,
          width: larguraPercent(),
          marginLeft: 'auto',
          marginRight: 'auto'
        }}

      >
          
        <TextInputForm
          name="nome"
          mode="flat"
          control={control}
          error={errors.nome}
          keyboardType="none"
          autoCapitalize="none"
          autoCorrect={false}
          label={tipoPessoa == "F" ? "Nome Completo" : "Razão Social"}
          style={styles.textInput}
        />
        <TextInputForm
          name="email"
          mode="flat"
          control={control}
          error={errors.email}
          keyboardType="email-address"
          textContentType="emailAddress"
          autoCapitalize="none"
          autoCorrect={false}
          label="E-mail"
          style={styles.textInput}
        />
        <TextInputForm
          name="senha"
          mode="flat"
          control={control}
          error={errors.senha}
          textContentType="password"
          secureTextEntry={true}
          label="Senha"
          style={styles.textInput}
        />
        <TextInputForm
          name="cpfcgc"
          mode="flat"
          keyboardType="number-pad"
          control={control}
          error={errors.cpfcgc}
          textContentType="none"
          maxLength={tipoPessoa == "F" ? 11 : 14}
          label={tipoPessoa == "F" ? "CPF - Somente números" : "CNPJ - Somente números"}
          style={styles.textInput}
        />
        <TextInputForm
          name="cidade"
          mode="flat"
          control={control}
          error={errors.cidade}
          textContentType="addressCity"
          label="Cidade"
          style={styles.textInput}
        />
        <TextInputForm
          name="cel1"
          mode="flat"
          keyboardType="number-pad"
          control={control}
          error={errors.cel1}
          textContentType="telephoneNumber"
          label="Celular"
          style={styles.textInput}
        />
        <TextInputForm
          name="refer1"
          mode="flat"
          keyboardType="none"
          control={control}
          error={errors.refer1}
          label="Referencia"
          style={styles.textInput}
        />

        <Button
          mode="contained"
          loading={loading}
          onPress={handleSubmit(handleUserRegister)}
          style={styles.buttonInput}
          buttonColor={theme.colors.tertiary}
        >
          Criar Conta
        </Button>

        <Typography 
          align="center" 
          fontSize='15' 
          color={theme.colors.secondary}
        >
          {info}
        </Typography>
        
      </ScrollView>
      <HeaderBotton></HeaderBotton>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingBottom: 5,
    paddingHorizontal: 0,
    flex: 1,
    // justifyContent: "center",
  },
  textInput: { fontSize: 12, marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: -10 },
  buttonInput: { marginLeft: 100, marginRight: 100, marginTop: 15, marginBottom: 20 },
  error: {
    color: "red",
    fontSize: 16,
    fontWeight: 'bold'
  },
});
