import React, { useEffect, useState } from "react";
import { CardMedia, Grid, Paper, TextField, styled } from "@mui/material";
import { ScrollView } from "react-native-gesture-handler";
import { Checkbox, PaperProvider, RadioButton, Text } from "react-native-paper";
import { theme } from "../../Global/Theme";
import AddIcon from '@mui/icons-material/Add';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ScaleIcon from '@mui/icons-material/Scale';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import {
  Button,
  Modal,
  Portal,
  Card,
} from 'react-native-paper';
import axiosInstance from "../../Services/api/axios";
import RemoveItem from "../../components/Gerais/Utils/RemoveItem";
import { Linking, View } from "react-native";

const Item = styled(Paper)(({ theme }) => ({
    textAlign: 'center'
  }));

export default function GadoGordoScreen() {    
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [quantidade, setQuantidade] = useState('');
    const [pesoAprox, setPesoAprox] = useState('');
    const [localizacao, setLocalizacao] = useState('');
    const [checkedTp, setCheckedTp] = useState('');
    const [visible, setVisible] = React.useState(false);           
    const [isLoading, setisLoading] = useState(false);
    const [isError, setisError] = useState(false);
    const [mensagemCriada, setMensagemCriada] = useState('');

    const onChangeQuantidade = (event) => {
      setQuantidade(event.target.value)
     }
     const onChangePesoAprox = (event) => {
      setPesoAprox(event.target.value)
     }
    const onChangeLocalizacao = (event) => {
      setLocalizacao(event.target.value)
     }


    useEffect(() => {
      setisLoading(true);
      axiosInstance
        .get("/Categoria/")
        .then((response) => {
          console.log(response.data);
          setCategorias(response.data);
          setisLoading(false);
        })
        .catch((err) => {        
          setisError(true);
          setisLoading(false);
        });
    }, []);
  
    
    const onSelect = (label, isSelected) => {          
      if (isSelected) {                
        selectedCategories.push(label);                
      } else {                      
        RemoveItem(selectedCategories,label)                
      }
      setSelectedCategories([...selectedCategories]);
    };
    
    const showModal = () => setVisible(true);
    const hideModal = () => setVisible(false);
    
    const larguraPercent = () => {
      if (innerWidth <  640) {
        return '100%'
      } else {
        return '60%'
      }
    };

    const containerStyle = {
      backgroundColor: 'white',
      padding: 20,
    };   

    useEffect(() => {
      const messageCriada = "Olá, estou no app Agenda Remates na aréa de captações: " +
        "Categoria: " + selectedCategories +
        " - Quantidade: " + quantidade +
        " - Peso Aproximado: " + pesoAprox +
        " - Localização: " + localizacao +
        ", interesse na venda: " + checkedTp;
      setMensagemCriada(messageCriada)
    }, [selectedCategories, quantidade, pesoAprox, localizacao, checkedTp]);



    return (
    <PaperProvider>
      <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{
        paddingBottom: 20,
        width: larguraPercent(),
        marginBottom: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto'
      }}>
          <Portal>
          <Modal visible={visible}
            onDismiss={() => hideModal}
            contentContainerStyle={containerStyle}
            style={{              
              justifyContent: "center",
              alignItems: "center",
              height: '50px',

            }}>
            
            <View>
            {categorias.map((category) => {              
              return (
              <Checkbox.Item 
                key={category.descricao}                     
                label={category.descricao}                 
                status={selectedCategories.includes(category.descricao) ? "checked" : "unchecked"}
                onPress={() => {
                  onSelect(category.descricao, !selectedCategories.includes(category.descricao));
                }}                
              />
              )})}
            </View>
              
            <Card.Actions>
              <Button onPress={hideModal} loading={isLoading}>Cancelar</Button>
              <Button onPress={hideModal} loading={isLoading} buttonColor="#182d1c" textColor="white">
              Confirmar
              </Button>
            </Card.Actions>
            

          </Modal>
        </Portal>
    
        <Grid container spacing={2}>
          <CardMedia
            style={{ backgroundSize: 'cover' }}
            component="img"
            alt="Agenda Remates"
            height={200}
            image="https://i.ibb.co/frYZkGL/Whats-App-Image-2023-07-20-at-10-13-58.jpg"
          />
          <Grid item xs={4}>
            <Item>
              <TextField                 
                style={{
                  backgroundColor: "white",
                }}                
                label="Categoria"                                                                                
                value={selectedCategories}
                InputLabelProps={{
                  style: { color: theme.colors.tertiary },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: theme.colors.tertiary,
                    },
                  },                          
                  marginLeft: '5px',                  
               }}
               InputProps={{
                    endAdornment: <AddIcon onClick={showModal}/>,                
                }}                
              />
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
              <TextField 
                style={{
                  backgroundColor: "white",
                }}
                onChange={onChangeQuantidade}
                value={quantidade}
                label="Quantidade"
                id="quantidade"
                InputLabelProps={{
                  style: { color: theme.colors.tertiary },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: theme.colors.tertiary,
                    },
                  },                                    
               }}
               InputProps={{
                    endAdornment: <FormatListNumberedIcon/>,                
               }}                                
              />
            </Item>
          </Grid>
          <Grid item xs={4}>
            <Item>
              <TextField 
                style={{
                  backgroundColor: "white",
                }}              
                onChange={onChangePesoAprox}
                value={pesoAprox}
                label="Peso Aproximado"
                id="pesoAprox"
                InputLabelProps={{
                  style: { color: theme.colors.tertiary },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: theme.colors.tertiary,
                    },
                  },                
                  marginRight: '5px',                    
               }}
               InputProps={{
                    endAdornment: <ScaleIcon/>,                
               }}                                
              />
            </Item>
          </Grid>
        </Grid>
        <TextField
          style={{
            backgroundColor: "white",
          }}
          label="Localização"
          value={localizacao}
          onChange={onChangeLocalizacao}
          InputLabelProps={{
            style: { color: theme.colors.tertiary },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: theme.colors.tertiary,
              },              
            },  
            marginTop: '10px',
            marginLeft: '5px',
            marginRight: '5px',                                  
         }}
         InputProps={{
            endAdornment: <MyLocationIcon/>,                
         }}                                          
        />
        <Text style={{ marginLeft: 15, marginTop: 10, color: theme.colors.tertiary }} >
          Tipo Venda
        </Text>
        <RadioButton.Group onValueChange={(value) => setCheckedTp(value)} value={checkedTp}>
          <RadioButton.Item
            style={{
              backgroundColor: "white",
              height: 30,

            }}
            labelStyle={{ color: theme.colors.tertiary }}
            color={theme.colors.tertiary}
            label="KG Vivo"
            value="KG Vivo" />
          <RadioButton.Item
            style={{ height: 30 }}
            labelStyle={{ color: theme.colors.tertiary }}
            color={theme.colors.tertiary}
            label="Rendimento"
            value="Rendimento" />
        </RadioButton.Group>   
        <Button
          icon="whatsapp"
          style={{ backgroundColor: "#54634d", borderRadius: 12, margin: 10 }}
          labelStyle={{ color: "white" }}
          theme={{ roundness: 0 }}
          mode="contained"
          onPress={() => {
            Linking.openURL('https://wa.me/+5555996138016?text=' + mensagemCriada);
          }}
        >
          Fale Conosco
        </Button>     
      </ScrollView>
    </PaperProvider >
)}