import { View } from 'react-native';
import {
  BottomNavigation,
  Text,
} from 'react-native-paper';
import React, { useState } from "react";
import Geolocation from '@react-native-community/geolocation';
import Geocoder from 'react-native-geocoding';
import Menu from '../../components/Gerais/Menu/Menu';
import { theme } from '../../Global/Theme';
import HeaderBotton from '../../components/HeaderBottom/HeaderBottom';
import GadoGordoScreen from './GadoGordoScreen';
import RemateScreen from './RemateScreen';
import VendaDiretaScreen from './VendaDiretaScreen';

export default function QuestionsScreen() {
  const [index, setIndex] = React.useState(0);
  const [currentLongitude, setCurrentLongitude] = useState('...');
  const [currentLatitude, setCurrentLatitude] = useState('...');
  const [locationStatus, setLocationStatus] = useState('');

  const getOneTimeLocation = () => {
    setLocationStatus('Buscando Localização...');
    Geolocation.getCurrentPosition(
      (position) => {
        setLocationStatus('Achamos sua localização');

        const currentLongitude =
          JSON.stringify(position.coords.longitude);

        const currentLatitude =
          JSON.stringify(position.coords.latitude);

        setCurrentLongitude(currentLongitude);
        setCurrentLatitude(currentLatitude);
      },
      (error) => {
        setLocationStatus(error.message);
      },
      {
        enableHighAccuracy: false,
        timeout: 30000,
        maximumAge: 1000
      },
    );
  };

  function extractFromAdress(components, type){
    for (var i=0; i<components.length; i++)
        for (var j=0; j<components[i].types.length; j++)
            if (components[i].types[j]==type) return components[i].long_name;
    return "";
}

  Geolocation.getCurrentPosition(
    (position) => {
      const currentLongitude =
        JSON.stringify(position.coords.longitude);

      const currentLatitude =
        JSON.stringify(position.coords.latitude);

      Geocoder.init("AIzaSyCLr-ixTEyTrImZpxH-9JeTUf9SM8h_mqU");
      Geocoder.from(currentLatitude, currentLongitude)
        .then(json => {          
          var city = extractFromAdress(json.results[0].address_components, "administrative_area_level_2");
          setLocationStatus(city);
        })
        .catch();

    }, (error) => console.log(error.message), {
    enableHighAccuracy: true, timeout: 20000, maximumAge: 1000
  }
  );

  const [routes] = React.useState([
    {
      key: 'remates',
      title: 'Remates',
      focusedIcon: 'home'
    },
    {
      key: 'gadogordo',
      title: 'Gado Gordo',
      focusedIcon: 'weight-kilogram',
      unfocusedIcon: 'weight-kilogram'
    },
    {
      key: 'vendadireta',
      title: 'Remate Virtual',
      focusedIcon: 'sign-direction-plus'
    }    
  ]);

  const renderScene = BottomNavigation.SceneMap({
    gadogordo: GadoGordoScreen,
    vendadireta: VendaDiretaScreen,
    remates: RemateScreen,
  });

  return (
    <View style={{ flex: 1 }}>
      <Menu location="Corretagens" />
      <Text
        variant="labelLarge"
        style={{
          backgroundColor: theme.colors.primary,
          textAlign: "center",
          padding: 10,
          color: theme.colors.secondary,
        }}
      >
        {'Captação de ' + routes[index].title}

      </Text>
      <BottomNavigation
        style={{marginBottom: 25}}
        compact={true}
        navigationState={{ index, routes }}
        onIndexChange={setIndex}
        renderScene={renderScene}
      />
      <HeaderBotton></HeaderBotton>
    </View>
  );
}
  