import React from "react";
import HeaderBotton from "../../components/HeaderBottom/HeaderBottom";
import Menu from "../../components/Gerais/Menu/Menu";
import { CardMedia, Grid, Typography, styled } from "@mui/material";
import Paper from '@mui/material/Paper';
import { theme } from "../../Global/Theme";
import { Appbar, IconButton } from "react-native-paper";
import { ScrollView } from "react-native-gesture-handler";
import { View } from "react-native";
import Carousel  from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.css';

export default function PartnersScreen() {
  const { innerWidth: width, innerHeight: height } = window;
 
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  return (
    <View style={{ flex: 1, backgroundColor: theme.colors.primary }}>
      <Menu location='history' />
      <Carousel>
        <Carousel.Item>
          <CardMedia            
            style={{ 
              backgroundSize: 'cover', 
              backgroundPosition: 'center top',
              backgroundAttachment: 'fixed',
              backgroundRepeat: 'no-repeat',                            
              width: innerWidth < 640 ? ('100%') : ('100%'),              
              marginLeft: 'auto',
              marginRight: 'auto'                            
            }}                
            component="img"
            alt="Agenda Remates"            
            height={300}
            image="https://i.ibb.co/9pz3p21/parterns1.jpg"
          />          
        </Carousel.Item>
        <Carousel.Item>
          <CardMedia
            style={{ 
              backgroundSize: 'cover', 
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',              
              position: 'relative',
              width: innerWidth < 640 ? ('100%') : ('100%'),              
              marginLeft: 'auto',
              marginRight: 'auto'                            
            }}                
            component="img"
            alt="Agenda Remates"            
            height={300}
            image="https://i.ibb.co/c1dqWxt/parterns2.jpg"
          />                    
        </Carousel.Item>
        <Carousel.Item>
          <CardMedia
            style={{ 
              backgroundSize: 'cover', 
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',              
              position: 'relative',
              width: innerWidth < 640 ? ('100%') : ('100%'),              
              marginLeft: 'auto',
              marginRight: 'auto'                            
            }}                
            component="img"
            alt="Agenda Remates"            
            height={300}
            image="https://i.ibb.co/0QNmSBF/parterns3.jpg"
          />                              
        </Carousel.Item>
        <Carousel.Item>
          <CardMedia
            style={{ 
              backgroundSize: 'cover', 
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',              
              position: 'relative',
              width: innerWidth < 640 ? ('100%') : ('100%'),              
              marginLeft: 'auto',
              marginRight: 'auto'                            
            }}                
            component="img"
            alt="Agenda Remates"            
            height={300}
            image="https://i.ibb.co/tZHcPN3/parterns4.jpg"
          />                                        
        </Carousel.Item>
        <Carousel.Item>
          <CardMedia
            style={{ 
              backgroundSize: 'cover', 
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',              
              position: 'relative',
              width: innerWidth < 640 ? ('100%') : ('100%'),              
              marginLeft: 'auto',
              marginRight: 'auto'                            
            }}                
            component="img"
            alt="Agenda Remates"            
            height={300}
            image="https://i.ibb.co/mFGKgS1/parterns5.jpg"
          />                                                  
        </Carousel.Item>
        <Carousel.Item>
          <CardMedia
            style={{ 
              backgroundSize: 'cover', 
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',              
              position: 'relative',
              width: innerWidth < 640 ? ('100%') : ('100%'),              
              marginLeft: 'auto',
              marginRight: 'auto'                            
            }}                
            component="img"
            alt="Agenda Remates"            
            height={300}
            image="https://i.ibb.co/0fk5hLz/parterns6.jpg"
          />                                                  
        </Carousel.Item>
        <Carousel.Item>
          <CardMedia
            style={{ 
              backgroundSize: 'cover', 
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',              
              position: 'relative',
              width: innerWidth < 640 ? ('100%') : ('100%'),              
              marginLeft: 'auto',
              marginRight: 'auto'                            
            }}                
            component="img"
            alt="Agenda Remates"            
            height={300}
            image="https://i.ibb.co/KwcbfqH/parterns7.jpg"
          />                                                  
        </Carousel.Item>
      </Carousel>   
      <Appbar
        style={{
          height: '50px',
          width: '100%',
          backgroundColor: theme.colors.primary,
          justifyContent: "flex-start",
          alignItems: "center",
        }}>
        <IconButton
          icon="circle"
          iconColor={theme.colors.tertiary}
          size={10}
        />
        <Typography color={theme.colors.secondary}>PARCEIROS</Typography>
      </Appbar>            
      <ScrollView 
        showsHorizontalScrollIndicator={false} 
        contentContainerStyle={{
          margin: 15,
          backgroundColor: theme.colors.primary,      
          alignSelf: "center"    
      }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6} sm={3} md={2} >
          <Item>
          <CardMedia          
              style={{ backgroundSize: 'cover' }}   
              component="img"
              alt="SICOB"
              height={200}
              width={100}
              image="https://i.ibb.co/gWfjbLM/Whats-App-Image-2023-09-19-at-09-15-42.jpg"
            />                                              
          </Item>
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Item>
          <CardMedia             
              component="img"
              alt="Casa de Carnes Santo Antonio"
              height={200}
              width={100}
              image="https://i.ibb.co/bsJ9m4Y/Whats-App-Image-2023-09-19-at-09-15-21-1.jpg"
            />                                  
          </Item>
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Item>
          <CardMedia              
              component="img"
              alt="Caverá Rações e Minerais"
              height={200}
              width={100}
              image="https://i.ibb.co/pWnKxPJ/Whats-App-Image-2023-09-19-at-11-23-07.jpg"
            />          
          </Item>
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <Item>
          <CardMedia              
              component="img"
              alt="SULTCH"
              height={200}
              width={100}              
              image="https://i.ibb.co/BPvvHzW/Whats-App-Image-2023-09-19-at-09-15-22.jpg"
            />                        
          </Item>
        </Grid>        
        <Grid item xs={6} sm={3} md={2}>
          <Item>
          <CardMedia
              component="img"
              alt="Arthur Pires de Freitas - Leiloeiro Rural"
              height={200}
              width={100}
              image="https://i.ibb.co/GkQyvH3/Whats-App-Image-2023-09-19-at-09-31-59.jpg"
            />                      
          </Item>
        </Grid>
      </Grid>

      </ScrollView>
      <HeaderBotton></HeaderBotton>        
    </View>
  );
}
