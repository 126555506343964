import { Linking } from "react-native";
import React from "react";
import { Avatar, Button, Card, IconButton } from "react-native-paper";
import { Leilao } from "../../../utils/types/Leilao.type";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useNavigation } from "@react-navigation/native";
import { theme } from "../../../Global/Theme";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
const logo = require('../../../../assets/image/logotipo_branco.png');
type IProps = {
  leilao: Leilao;
};


export type RootStackParamList = {
  SaleScreen: { leilao: Leilao } | undefined;
};

export default function CardLeilao({ leilao }: IProps) {
  const { innerWidth: width, innerHeight: height } = window;
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const handlePress = () => {
    navigation.navigate("SaleScreen", {
      leilao,
    })
  };
  var formatter = new Intl.DateTimeFormat('pt-BR');
  var date = new Date(leilao.DATLEI);

  const larguraCard = () => {    
    if (innerWidth <  640) {
      return innerWidth - 10
    } else {
      return '400px'
    }
  };

  const IconVideo = () => (
    <Button    
      icon="movie-open-play"    
      textColor={theme.colors.primary}
      buttonColor="white"  
      onPress={() =>
        Linking.openURL(leilao.LINKTRANSMISSAO1 || leilao.LINKTRANSMISSAO2)}
    >
      Ao Vivo
    </Button>
    
  );
  
  const IconCatalogo = () => (
    <Button    
      icon="file-document"    
      textColor={theme.colors.primary}
      buttonColor="white"  
      onPress={() =>
        Linking.openURL(leilao.URLCATALOGO)}
    >
      Catalogo
    </Button>
  );
  const LeftContent = (props) => (
    <Avatar.Image
      {...props}
      size={30}
      style={{ backgroundColor: theme.colors.primary }}
      source={{ uri: "https://i.ibb.co/cg0tzRz/icone-agenda-dourado.png" }}
    />
  );
  return (
    <Card
      onPress={handlePress}
      theme={{ roundness: 0 }}
      style={{
        marginVertical: 0,
        borderRadius: 12,
        backgroundColor: theme.colors.primary,
        marginTop: '50px',
        marginLeft: '20px',                
        width: larguraCard(),
        height: '600px'
      }}
    >
      <Card.Title
        title={leilao.LEILAO}
        titleStyle={{ color: theme.colors.secondary, fontSize: 12 }}
        subtitle={formatter.format(date)}
        subtitleStyle={{ color: theme.colors.secondary, fontSize: 10 }}
        left={LeftContent}
      />

      <Card.Cover        
        resizeMode="cover"
        style={{          
          marginLeft: 10,          
          backgroundColor: theme.colors.primary,
          height: '470px',
          width: '95%',          
          borderRadius: 12,

        }}
        defaultSource={logo}
        source={{
          uri:
            "https://loterural.s3.us-east-2.amazonaws.com/mobile_leilao_img_" +
            leilao.ID +
            ".png" ||
            "https://lolitajoias.com.br/wp-content/uploads/2020/09/no-image.jpg",
        }}
      />
      <Card.Actions style={{ justifyContent: "flex-start" }}>
        <TouchableOpacity
          onPress={() =>
            navigation.navigate("SaleScreen", {
              leilao,
            })
          }
        >

          {!!leilao.LINKTRANSMISSAO1 || leilao.LINKTRANSMISSAO2 && (
            <IconVideo/>
          )}
          {leilao.URLCATALOGO && (
            <IconCatalogo/>
          )}

        </TouchableOpacity>
      </Card.Actions >
    </Card >
  );
}
