import { Text, View } from "react-native";
import React, { useEffect, useState } from "react";

import {
  ActivityIndicator,
} from "react-native-paper";
import { Leilao } from "../../utils/types/Leilao.type";
import CardLeilao from "../../components/Cards/CardLeilao/CardLeilao";
import { ScrollView } from "react-native-gesture-handler";
import { getLeiloes } from "../../Services/api/AuthServices";
import { theme } from "../../Global/Theme";
import HeaderBotton from "../../components/HeaderBottom/HeaderBottom";
import Menu from "../../components/Gerais/Menu/Menu";
import { Grid } from "@mui/material";
import moment from "moment";

class HHTTPError extends Error { }

export default function ProductsScreen({ navigation }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [leiloes, setLeiloes] = useState<Leilao[] | null>([]);

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", async () => {
      try {
        setIsLoading(true);
        const data = await getLeiloes();

        //order array DATLEI desc
        //order by DATLEI asc
        console.log(data);
        const compare = (a, b) => {
          const dateA = moment(a.DATLEI);
          const dateB = moment(b.DATLEI);
          return dateB.diff(dateA);
        };
        
        const reverseData = data.sort(compare);
        setLeiloes(reverseData);
        

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(true);
      }
    });
    return unsubscribe;
  }, [navigation]);

  return (
    <>
      <Menu location="Remates" />
      {isLoading ? (
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <ActivityIndicator animating={true} color="green" />
          <Text>Buscando Leilões...</Text>
        </View>
      ) : (
        <View style={{ flex: 1 }} >

          <ScrollView               
              showsHorizontalScrollIndicator={false} 
              contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
              style={{ backgroundColor: theme.colors.secondary, 
              flex: 1 }}>

            {error ? (
              <>
                <View
                  style={{
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ActivityIndicator animating={true} color="white" />
                  <Text>Tivemos problemas.Tente novamente...</Text>
                </View>
              </>
            ) : (
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}
                alignItems="center"
                justifyContent="center">

                {leiloes.map((lote) => (
                  <CardLeilao key={lote.ID} leilao={lote} />
                ))}
              </Grid>
            )}
          </ScrollView>
          <HeaderBotton></HeaderBotton>
        </View>
      )}
    </>
  );
}
