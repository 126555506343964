import axiosInstance from "./axios";

const bodyCidade = {
  id: 0,
  cidade: "",
  estado: "",
  pais: ""
};

const bodySignup = {
  id: 0,
  codfat: 0,
  codcla: "",
  rg: "",
  datnas: "",
  cep: "",
  endereco: "",
  numero: "",
  bairro: "",
  cidpro: 0,
  ceppro: "",
  endpro: "",
  baipro: "",
  caipro: "",
  telres: "",
  telcom: "",
  cel2: "",
  propri: "",
  pai: "",
  mae: "",
  obs: "CADASTRO ENVIADO VIA APP",
  banco: "",
  agencia: "",
  conta: "",
  renda: 0,
  gta: "",
  profiss: "",
  empres: "",
  refer2: "",
  telrefere1: "",
  telrefere2: "",
};

export async function Signin(cpf: string, password: string) {
  try {
    const { data } = await axiosInstance.get(
      `Clientes/Login/${cpf}/${password}`
    );
    return data;
  } catch (error) {
    console.log("error", error);
  }
}

export async function getUser(id: number) {
  try {
    const { data } = await axiosInstance.get(`Clientes/${id}`);
    console.log("ID", data);
    return data[0];
  } catch (error) {
    console.log("error", error);
  }
}

export async function signUp(body: any) {
  try {
    console.log("body", body);
    const { data } = await axiosInstance.post(`Clientes`, {
      ...bodySignup,
      ...body,
    });
    console.log("signUp", data);
    return data;
  } catch (error) {
    console.log("error signUp", error);
    throw new Error("Erro ao criar conta");
  }
}
export async function getCity(city) {
  try {
    const { data } = await axiosInstance.get(`Cidade/${city}`);
    return data;
  } catch (error) {
    console.log("error getCity", error);
    throw new Error("Erro ao consultar cidade");
  }
}

export async function setLance(lance: any) {
  try {
    const { data } = await axiosInstance.post("Lance", {
      ...lance,
    });
    return data;
  } catch (error) {
    console.log("error", error);
    throw new Error("Erro ao dar lance");
  }
}

export async function checkDocument(document: string) {
  try {
    const { data } = await axiosInstance.get(
      `Clientes/RecuperarID/${document}`
    );
    return data;
  } catch (error) {
    console.log("error", error);
    throw new Error("Usuário não encontrado");
  }
}

export async function updatePassword(id: string, newPassword: string) {
  try {
    const { data } = await axiosInstance.put(
      `Clientes/UpdateSenha/${id}/${newPassword}`
    );
    return data;
  } catch (error) {
    console.log("error updatePassword", error);
    throw new Error("Usuário não encontrado");
  }
}

export async function getLeilao(id: string) {
  try {
    const { data } = await axiosInstance.get(`/LeiloesLotes/${id}`);
    return data;
  } catch (error) {
    console.log("error ao obter leilão", error);
    throw new Error("error ao obter leilão");
  }
}

export async function getLeiloes() {
  try {
    const { data } = await axiosInstance.get(`/LeiloesLotes`);
    return data;
  } catch (error) {
    console.log("error ao obter leilão", error);
    throw new Error("error ao obter leilão");
  }
}


export async function getCategorias() {
  try {
    const { data } = await axiosInstance.get(`/Categoria/`);
    return data;
  } catch (error) {
    console.log("error ao obter Categoria", error);
    throw new Error("error ao obter Categoria");
  }
}
