import * as React from 'react';
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import { useNavigation } from "@react-navigation/native";
import { ImageBackground } from 'react-native';
const logo = require('../../../../assets/image/logotipo_branco.png');
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { theme } from '../../../Global/Theme';


const url = 'https://i.ibb.co/DLBRG7j/icone-logo.png';

const Item = styled('div')(() => ({
  height: '80px',    
  background: theme.colors.primary,
}));;

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  margin: 'auto',   
  width: '100%', 
  height: '50px',
  marginLeft: '10px',
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '0px solid currentColor',
    },
  },  
}));

const ImageSrc = styled('span')(({ theme }) => ({
  position: 'absolute',    
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  [theme.breakpoints.down('sm')]: {
    backgroundSize: '50px 40px',  },
  [theme.breakpoints.up('sm')]: {
    backgroundSize: '90px 60px',
  },  
  backgroundPosition: 'center 50%',
}));

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',  
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

const ImageMarked = styled('span')(({ theme }) => ({
  height: 3,
  width: '100%',
  backgroundColor: '#9e691d',
  borderRadius: 12,
  position: 'absolute',
  top: -2,
  left: '0',
  transition: theme.transitions.create('opacity'),
}));


export default function ButtonWeb(tag) {
  const navigation = useNavigation<any>();
  return (
    <Box sx={{ flexGrow: 1, display: 'flex' }}>
      <Grid container spacing={1} columns={{ xs: 6, md: 12 }}>
        <Grid item xs={2} md={3} lg={2}>
          <Item>
            <ImageButton focusRipple key={tag} onClick={() => navigation.navigate('Remates')}>
              <ImageSrc 
                style={{
                  backgroundImage: `url(${url})`,
                  backgroundRepeat: 'no-repeat'
                }} 
              />              
              <ImageMarked className="MuiImageMarked-root" />              
              <Typography                  
                component="span"
                variant="h6"                
                sx={{       
                  color: 'white',
                  fontWeight: 'bold',           
                  position: 'relative',
                  marginTop: '30px',                  
                  p: 4,                  
                }}
                >
                REMATES
              </Typography>
              
            </ImageButton>
          </Item>
        </Grid>
        <Grid item xs={2} md={3} lg={2}>
          <Item>    
            <ImageButton focusRipple key={tag} onClick={() => navigation.navigate('Captacao')}>
              <ImageSrc style={{
                backgroundImage: `url(${url})`,
                backgroundRepeat: 'no-repeat'
              }}
              />              
              <ImageMarked className="MuiImageMarked-root" />
              <Typography                
                component="span"
                variant="h6"
                color="inherit"
                sx={{       
                  color: 'white',
                  fontWeight: 'bold',           
                  position: 'relative',
                  marginTop: '30px',                  
                  p: 4,                  
                }}
              >
                VENDA CONOSCO
              
              </Typography>
              
            </ImageButton>
          </Item>
        </Grid>
        <Grid item xs={2} md={3} lg={2}>
          <Item>
            <ImageButton focusRipple key={tag} onClick={() => navigation.navigate('Cadastrar')}>
            <ImageSrc style={{
              backgroundImage: `url(${url})`,
              backgroundRepeat: 'no-repeat'
              }} 
            />
            <ImageMarked className="MuiImageMarked-root" />            
            <Typography              
              component="span"
              variant="h6"             
              sx={{       
                color: 'white',
                fontWeight: 'bold',           
                position: 'relative',
                marginTop: '30px',                  
                p: 4,
              }}
            >
              CADASTRO
            
            </Typography>          
          </ImageButton>
        </Item>
        </Grid>
      </Grid>
      <ImageBackground
        resizeMode='cover'
        style={{
          width: '200px',
          height: '80px',              
        }}
        source={logo}
      />
    </Box>
  )
}