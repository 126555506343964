import * as React from 'react';
const logo = require('../../../../assets/image/logotipo_branco.png');
import MenuMobile from '../MenuMobile/MenuMobile';
import { CardMedia } from '@mui/material';

export default function ButtonMobile(tag) {
  return (
    <>
      <MenuMobile tag={tag}/>            
      <CardMedia
        component="img"
        sx={{ width: 200, marginLeft: 'auto' }}
        image={logo}
        alt="Logo Agenda Remates"
      />
      </>
  )
}