import { Text, View } from "react-native";
import React from "react";
import { useQuery } from "react-query";
import { Card, RadioButton, Provider, Portal, Modal, Button, ActivityIndicator } from "react-native-paper";
import CardAnuncio from "../../components/Cards/CardAnuncio/CardAnuncio";
import { ScrollView } from "react-native-gesture-handler";
import axiosInstance from "../../Services/api/axios";

export default function AnnouncementScreen() {

  const containerStyle = {
    backgroundColor: 'white',
    padding: 5,
    borderRadius: 12,
    height: '50%',
    width: '100%',
  };

  const getCategorias = async () => {
    const res = await fetch(axiosInstance + "/Categoria/");
    return res.json();
  };

  const { isLoading, error, data } = useQuery('minhasCategorias', getCategorias);
  const [nomeCategoria, setNomeCategoria] = React.useState('0');

  const [visible, setVisible] = React.useState(false);
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);

  if (isLoading)
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator animating={true} color="green" />
        <Text>Buscando Categorias...</Text>
      </View>
    );

  if (error)
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <Text>Tivemos problemas.Tente novamente...</Text>
      </View>
    );

  return (
    <Provider>
      <Portal>
        <Modal visible={visible}
          onDismiss={hideModal}
          contentContainerStyle={containerStyle}
          style={{ marginLeft: 10, marginRight: 10 }}
        >
          <Text style={{ margin: 10, fontSize: 18, fontWeight: 'bold' }}>Escolha a Categoria</Text>
          <View style={{ flex: 1 }}>
            <ScrollView style={{ flex: 1, paddingHorizontal: 20 }}>

              <RadioButton.Group
                onValueChange={value => setNomeCategoria(value)}
                value={nomeCategoria}
              >

                {
                  data.map((minhasCategorias) => {
                    return <RadioButton.Item
                      style={{ height: 40 }}
                      labelStyle={{ color: 'black', fontWeight: 'bold' }}
                      label={minhasCategorias.descricao}
                      value={minhasCategorias.id}
                    />
                  })
                }
                <RadioButton.Item
                  style={{ height: 40 }}
                  labelStyle={{ color: 'black', fontWeight: 'bold' }}
                  label="Todas"
                  value="0" />
              </RadioButton.Group>
            </ScrollView>
          </View>
          <Card.Actions>
            <Button buttonColor="#182d1c" textColor="white">Cancelar</Button>
            <Button buttonColor="#182d1c" textColor="white" onPress={hideModal}>Confirmar</Button>
          </Card.Actions>
        </Modal>
      </Portal>
      <Button style={{
        margin: 10,
        backgroundColor: '#182d1c',
      }}
        textColor='white'
        onPress={showModal}
        mode='contained-tonal'
        icon="account-search"
      >
        Escolha a Categoria
      </Button>
      <CardAnuncio id={nomeCategoria}></CardAnuncio>
    </Provider>
  );
}
