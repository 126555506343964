import { View } from "react-native";
import React from "react";
import HeaderBotton from "../../components/HeaderBottom/HeaderBottom";
import Menu from "../../components/Gerais/Menu/Menu";
import { CardMedia, Typography, styled } from "@mui/material";
import Paper from '@mui/material/Paper';
import { theme } from "../../Global/Theme";
import { Appbar, IconButton } from "react-native-paper";
import { ScrollView } from "react-native-gesture-handler";
import Carousel  from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.css';

export default function HistoryScreen() {
  return (
    <View style={{ minHeight: '100%'}}>
      <Menu location='history' />
      <ScrollView style={{
        backgroundColor: theme.colors.primary                
      }}>
        <Carousel>
        <Carousel.Item>
          <Appbar.Header
            mode="center-aligned"
            style={{
              backgroundColor: theme.colors.primary,
              height: '200px',
              width: '100%',
              justifyContent: innerWidth < 640 ? ("flex-start") : ("center"),
              alignItems: "center"
            }}
          >
          <CardMedia            
            style={{ 
              backgroundSize: 'cover', 
              backgroundPosition: 'center top',
              backgroundAttachment: 'fixed',
              backgroundRepeat: 'no-repeat',                            
              width: '12%',                        
              margin: '20px',
              borderRadius: '10px',
            }}                
            component="img"
            alt="Agenda Remates"            
            height={200}
            image="https://i.ibb.co/h9WFdfs/eq1.png"
          />
          <CardMedia            
            style={{ 
              backgroundSize: 'cover', 
              backgroundPosition: 'center top',
              backgroundAttachment: 'fixed',
              backgroundRepeat: 'no-repeat',                            
              width: '12%',              
              margin: '20px',
              borderRadius: '10px',
            }}                
            component="img"
            alt="Agenda Remates"            
            height={200}
            image="https://i.ibb.co/sKDKDjj/eq2.png"
          />    
          <CardMedia            
            style={{ 
              backgroundSize: 'cover', 
              backgroundPosition: 'center top',
              backgroundAttachment: 'fixed',
              backgroundRepeat: 'no-repeat',                            
              width: '12%',              
              margin: '20px',
              borderRadius: '10px',
            }}                
            component="img"
            alt="Agenda Remates"            
            height={200}
            image="https://i.ibb.co/4FqVMmM/eq3.png"
          />
          <CardMedia            
            style={{ 
              backgroundSize: 'cover', 
              backgroundPosition: 'center top',
              backgroundAttachment: 'fixed',
              backgroundRepeat: 'no-repeat',                            
              width: '12%',              
              margin: '20px',
              borderRadius: '10px',
            }}                
            component="img"
            alt="Agenda Remates"            
            height={200}
            image="https://i.ibb.co/FnpJp5L/eq4.png"
          />
          <CardMedia            
            style={{ 
              backgroundSize: 'cover', 
              backgroundPosition: 'center top',
              backgroundAttachment: 'fixed',
              backgroundRepeat: 'no-repeat',                            
              width: '12%',              
              margin: '20px',
              borderRadius: '10px',
            }}                
            component="img"
            alt="Agenda Remates"            
            height={200}
            image="https://i.ibb.co/6wSs2Yc/eq5.png"
          />
          <CardMedia            
            style={{ 
              backgroundSize: 'cover', 
              backgroundPosition: 'center top',
              backgroundAttachment: 'fixed',
              backgroundRepeat: 'no-repeat',                            
              width: '12%',              
              margin: '20px',
              borderRadius: '10px',
            }}                
            component="img"
            alt="Agenda Remates"            
            height={200}
            image="https://i.ibb.co/3yB0MdC/eq6.png"
          />    
          </Appbar.Header>
        </Carousel.Item>
        <Carousel.Item>
          <CardMedia
            style={{ 
              backgroundSize: 'cover', 
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',              
              position: 'relative',
              width: innerWidth < 640 ? ('100%') : ('20%'),              
              marginLeft: 'auto',
              marginRight: 'auto'                            
            }}                
            component="img"
            alt="Agenda Remates"            
            height={300}
            image="https://i.ibb.co/rkvP8Gq/qm2.jpg"
          />                    
        </Carousel.Item>
        <Carousel.Item>
          <CardMedia
            style={{ 
              backgroundSize: 'cover', 
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',              
              position: 'relative',
              width: innerWidth < 640 ? ('100%') : ('20%'),              
              marginLeft: 'auto',
              marginRight: 'auto'                            
            }}                
            component="img"
            alt="Agenda Remates"            
            height={300}
            image="https://i.ibb.co/cxFMXDw/qm3.jpg"
          />                              
        </Carousel.Item>
        <Carousel.Item>
          <CardMedia
            style={{ 
              backgroundSize: 'cover', 
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',              
              position: 'relative',
              width: innerWidth < 640 ? ('100%') : ('40%'),              
              marginLeft: 'auto',
              marginRight: 'auto'                            
            }}                
            component="img"
            alt="Agenda Remates"            
            height={300}
            image="https://i.ibb.co/Wc6h3LR/qm4.jpg"
          />                                        
        </Carousel.Item>
        <Carousel.Item>
          <CardMedia
            style={{ 
              backgroundSize: 'cover', 
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',              
              position: 'relative',
              width: innerWidth < 640 ? ('100%') : ('30%'),              
              marginLeft: 'auto',
              marginRight: 'auto'                            
            }}                
            component="img"
            alt="Agenda Remates"            
            height={300}
            image="https://i.ibb.co/tPc0FrN/qm5.jpg"
          />                                                  
        </Carousel.Item>
<Carousel.Item>
          <CardMedia
            style={{ 
              backgroundSize: 'cover', 
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',              
              position: 'relative',
              width: innerWidth < 640 ? ('100%') : ('30%'),              
              marginLeft: 'auto',
              marginRight: 'auto'                            
            }}                
            component="img"
            alt="Agenda Remates"            
            height={300}
            image="https://i.ibb.co/9bBSs3X/qm6.jpg"
          />                                                  
        </Carousel.Item>
        <Carousel.Item>
          <CardMedia
            style={{ 
              backgroundSize: 'cover', 
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',              
              position: 'relative',
              width: innerWidth < 640 ? ('100%') : ('30%'),              
              marginLeft: 'auto',
              marginRight: 'auto'                            
            }}                
            component="img"
            alt="Agenda Remates"            
            height={300}
            image="https://i.ibb.co/Rvm7Xqt/qm7.jpg"
          />                                                  
        </Carousel.Item>  
      </Carousel>      
        <Appbar
          style={{
            height: '70px',
            width: '100%',
            backgroundColor: theme.colors.primary,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
        <IconButton
          icon="circle"
          iconColor={theme.colors.tertiary}
          size={10}
        />
        <Typography color={theme.colors.secondary}>CONHEÇA NOSSA HISTÓRIA</Typography>
      </Appbar>
      <Typography style={{ margin: 10 }} color={theme.colors.secondary}>
      A Agenda Remates é uma empresa fundada no ano de 1983 pelo Dr. Homero Tarrago, Médico Veterinário e Leiloeiro Rural. A Agenda, desde o seu início, teve foco na realização de remates no Alegrete trazendo credibilidade e segurança aos seus clientes. Com o passar dos anos os tradicionais remates da QUINTA no Alegrete se tornaram referência e destino certo para produtores de todo estado do RS.
      </Typography>                          
      <Typography style={{ margin: 10 }} color={theme.colors.secondary}>
      Sempre pensando em inovar e acompanhar as tecnologias e negócios, mas sem perder o nosso foco, hoje contamos com mais dois sócios na empresa: Arthur Freitas, Leiloeiro Rural, e o João Pedro Alves, Médico Veterinário e Corretor de Imóveis. Ambos são nossos parceiros desde muitos anos e hoje integram a equipe AGENDA. 
      </Typography>                          
      <Typography style={{ margin: 10 }} color={theme.colors.secondary}>
      Do início até o ano de 2023 são 40 anos de remates, mais de 1.420 leilões realizados e mais de “um milhão e meio” (1.500.000) de animais já passaram pelo nosso martelo. Temos um amplo cadastro, contamos com vinte e três (23) colaboradores, clientes das mais distintas localizações, vários deles atravessando gerações, que acompanham nosso trabalho desde o início e se mantendo por décadas clientes AGENDA REMATES. Todos leilões são transmitidos e abrangem os mais distintos rincões deste País, levando a qualidade dos nossos vendedores ao maior público possível, sempre com a garantia da AGENDA.
      </Typography>                          
      <Typography style={{ margin: 10 }} color={theme.colors.secondary}>
      Em 40 anos de AGENDA o Alegrete figurou, em mais de 30 anos, como a maior praça de venda de gado em pé do estado do RS.
      </Typography>                          
      <Typography style={{ margin: 10 }} color={theme.colors.secondary}>      
      VALORIZE SEU GADO, VENDA CONOSCO!
      </Typography>                                
      </ScrollView >      
      <HeaderBotton></HeaderBotton>
    </View>
  );
}
