import * as React from 'react';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigation } from "@react-navigation/native";
import { theme } from '../../../Global/Theme';
import { View } from 'react-native';
import styled from '@emotion/styled';

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 5,
  right: 0,
  top: 5,
  bottom: 0,  
  backgroundPosition: 'left 0',
  backgroundSize: '60px 30px'

});

const urlVenda = 'https://i.ibb.co/sVXTF82/icone-venda.png';
const urlQuemSomos = 'https://i.ibb.co/cN9qykP/icone-quem-somos.png';
const urlParceiros = 'https://i.ibb.co/xs0tXys/icone-parceiros.png';
const urlCadastro = 'https://i.ibb.co/xz5BjMx/icone-cadastro.png';
const urlRemate = 'https://i.ibb.co/XDLWqPP/icone-remates.png';
const urlHome = 'https://i.ibb.co/y51wpws/icone-home.png';
const urlFaleConosco = 'https://i.ibb.co/FbbrhYy/icone-fale-conosco.png';
const urlGaleria = 'https://i.ibb.co/WD5CPPy/icone-galeria.png';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: '5px',
    minWidth: 180,
    color:
      'white',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },    
  },
}));

export default function MenuMobile(props) {
    const navigation = useNavigation<any>();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleHome = () => {
      navigation.navigate('Home')
      handleClose();
    };
    const handleRemates = () => {
      navigation.navigate('Remates')
      handleClose();
    };
    const handleCaptacao = () => {
      navigation.navigate('Captacao')
      handleClose();
    };
    const handleCadastro = () => {
      navigation.navigate('Cadastrar')
      handleClose();
    };
    const handleQuemSomos = () => {
      navigation.navigate('QuemSomos')
      handleClose();
    };
    const handleParceiros = () => {
      navigation.navigate('Parceiros')
      handleClose();
    };
    const handleContato = () => {
      navigation.navigate('FaleConosco')
      handleClose();
    };
    const handleGaleria = () => {
      navigation.navigate('Galeria de Fotos')
      handleClose();
    };

    return (
    <View>
       <IconButton               
        sx={{ 
          color: props.tag !== "principal" ? ('white') : (theme.colors.primary), 
          fontSize: 'large', 
          marginLeft: '10px', 
          width: '50px',          
        }}        
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {props.tag !== "principal" ? (
          <>
          <MenuItem onClick={() => handleRemates()}>
            <ImageSrc 
              style={{
                backgroundImage: `url(${urlRemate})`,
                backgroundRepeat: 'no-repeat'
              }} 
            />    
            <Typography style={{ color: theme.colors.tertiary, marginLeft: 50 }}>
              REMATES
            </Typography>          
          </MenuItem>
          <MenuItem onClick={() => handleCaptacao()}>
            <ImageSrc 
              style={{
                backgroundImage: `url(${urlVenda})`,
                backgroundRepeat: 'no-repeat'
              }} 
            />    
            <Typography style={{ color: theme.colors.tertiary, marginLeft: 50 }}>
              VENDA CONOSCO
            </Typography>          
          </MenuItem>
          <MenuItem onClick={() => handleCadastro()}>
            <ImageSrc 
              style={{
                backgroundImage: `url(${urlCadastro})`,
                backgroundRepeat: 'no-repeat'
              }} 
            />    
            <Typography style={{ color: theme.colors.tertiary, marginLeft: 50 }}>
              CADASTRO
            </Typography>          
          </MenuItem>
          <MenuItem onClick={() => handleParceiros()}>
            <ImageSrc 
              style={{
                backgroundImage: `url(${urlParceiros})`,
                backgroundRepeat: 'no-repeat'
              }} 
            />    
            <Typography style={{ color: theme.colors.tertiary, marginLeft: 50 }}>
              PARCEIROS
            </Typography>          
          </MenuItem>          
          <MenuItem onClick={() => handleGaleria()}>
            <ImageSrc 
              style={{
                backgroundImage: `url(${urlGaleria})`,
                backgroundRepeat: 'no-repeat'
              }} 
            />    
            <Typography style={{ color: theme.colors.tertiary, marginLeft: 50 }}>
              GALERIA
            </Typography>          
          </MenuItem>          
          </>
        ) :
        (<>
          <MenuItem onClick={() => handleHome()}>
            <ImageSrc 
              style={{
                backgroundImage: `url(${urlHome})`,
                backgroundRepeat: 'no-repeat'
              }} 
            />    
            <Typography style={{ color: theme.colors.tertiary, marginLeft: 50 }}>
              HOME
            </Typography>          
          </MenuItem>
          <MenuItem onClick={() => handleRemates()}>
            <ImageSrc 
              style={{
                backgroundImage: `url(${urlRemate})`,
                backgroundRepeat: 'no-repeat'
              }} 
            />    
            <Typography style={{ color: theme.colors.tertiary, marginLeft: 50 }}>
              REMATES
            </Typography>          
          </MenuItem>
          <MenuItem onClick={() => handleCaptacao()}>
            <ImageSrc 
              style={{
                backgroundImage: `url(${urlVenda})`,
                backgroundRepeat: 'no-repeat'
              }} 
            />    
            <Typography style={{ color: theme.colors.tertiary, marginLeft: 50 }}>
              VENDA CONOSCO
            </Typography>          
          </MenuItem>
          <MenuItem onClick={() => handleCadastro()}>
            <ImageSrc 
              style={{
                backgroundImage: `url(${urlCadastro})`,
                backgroundRepeat: 'no-repeat'
              }} 
            />    
            <Typography style={{ color: theme.colors.tertiary, marginLeft: 50 }}>
              CADASTRO
            </Typography>          
          </MenuItem>
          <MenuItem onClick={() => handleQuemSomos()}>
            <ImageSrc 
              style={{
                backgroundImage: `url(${urlQuemSomos})`,
                backgroundRepeat: 'no-repeat'
              }} 
            />    
            <Typography style={{ color: theme.colors.tertiary, marginLeft: 50 }}>
              EMPRESA
            </Typography>          
          </MenuItem>          
          <MenuItem onClick={() => handleParceiros()}>
            <ImageSrc 
              style={{
                backgroundImage: `url(${urlParceiros})`,
                backgroundRepeat: 'no-repeat'
              }} 
            />    
            <Typography style={{ color: theme.colors.tertiary, marginLeft: 50 }}>
              PARCEIROS
            </Typography>          
          </MenuItem>          
          <MenuItem onClick={() => handleGaleria()}>
            <ImageSrc 
              style={{
                backgroundImage: `url(${urlGaleria})`,
                backgroundRepeat: 'no-repeat'
              }} 
            />    
            <Typography style={{ color: theme.colors.tertiary, marginLeft: 50 }}>
              GALERIA
            </Typography>          
          </MenuItem>          
          <MenuItem onClick={() => handleContato()}>
            <ImageSrc 
              style={{
                backgroundImage: `url(${urlFaleConosco})`,
                backgroundRepeat: 'no-repeat'
              }} 
            />    
            <Typography style={{ color: theme.colors.tertiary, marginLeft: 50 }}>
              CONTATO
            </Typography>          
          </MenuItem>                    
        </>)
        }
      </StyledMenu>
    </View>
  );
}
