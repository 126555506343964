import { SafeAreaView, StyleSheet, Image, View, Dimensions, ImageBackground } from "react-native";
import React, { useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { theme } from "../../Global/Theme";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import HeaderBotton from "../../components/HeaderBottom/HeaderBottom";
import Menu from "../../components/Gerais/Menu/Menu";
import { Modal } from "react-native-paper";

const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#9e691d',
  '&:hover': {
    backgroundColor: '#b26500',
  },
}));

const styles = StyleSheet.create({
  geral: {
    flex: 1,
    justifyContent: "space-between",
    flexDirection: "row",
    backgroundColor: theme.colors.primary,
    marginBottom: 30,
  },
  container: {
    flex: 1,
    justifyContent: "space-between",
    flexDirection: "row",
    backgroundImage: "url(" + 'https://i.ibb.co/L0j9mwy/Whats-App-Image-2023-07-17-at-10-04-26-1.jpg' + ")",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: 10,
    borderRadius: 12,
    
  },
  quadradoA: {      
    width: '45%',
    height: 50,    
    margin: 20,
    marginTop: 'auto',
    marginLeft: 'auto',    
  },
  quadradoB: {
    borderRadius: 12,
    height: 50,
    width: 150,
    flex: 1,
    margin: 20,
    marginTop: 'auto',
  },
  quadradoC: {
    borderRadius: 12,    
    height: 50,
    flex: 2,
    marginBottom: 15,
    marginRight: 15,
    marginTop: 'auto',
  }
});

export default function HomeScreen() {
  const navigation = useNavigation<any>();
  const [openPopup, setOpenPopup] = useState(false);
  const handleQuemSomos = () => {
    navigation.navigate("QuemSomos")
  };
  const handleFaleConosco = () => {
    navigation.navigate("FaleConosco")
  };

  const larguraPercent = () => { 
    if (innerWidth <  640) {
      return '95%'
    } else {
      return '30%'
    }
  };
  return (
    <View style={{ height: '100%' }}
    >
      <Menu location="Home" />
      <View style={styles.geral}>
        <SafeAreaView style={styles.container}>
          <View style={styles.quadradoA}>
          </View>
          <View style={styles.quadradoC}>
            <Button style={{ color: theme.colors.secondary, 
                             backgroundColor: theme.colors.tertiary }}
              onClick={handleQuemSomos}>
              Quem Somos
            </Button>
          </View>
          <View style={styles.quadradoC}>
            <Button style={{ color: theme.colors.secondary, 
                              backgroundColor: theme.colors.tertiary }}

              onClick={handleFaleConosco}>
              Fale Conosco
            </Button>
          </View>
        </SafeAreaView>
        <Modal
          visible={openPopup}
          onDismiss={() => setOpenPopup(false)}
          contentContainerStyle={{ 
            width: larguraPercent(),
            height: `90%`,
            backgroundColor: 'white', 
            padding: 10,
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: 12,
          }}
        >          
          <Image 
            source={{ uri: "https://i.ibb.co/XxHvhHy/leilao-MAIO.jpg" }} 
            resizeMode="cover"
            style={{ width: '100%', height: '100%', borderRadius: 12 }} />
          
          </Modal>

      </View >
      <HeaderBotton></HeaderBotton>
    </View >
  );
}

