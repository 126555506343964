import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { View } from 'react-native';
import Menu from '../../components/Gerais/Menu/Menu';
import { ScrollView } from 'react-native-gesture-handler';
import { theme } from '../../Global/Theme';
import HeaderBotton from '../../components/HeaderBottom/HeaderBottom';
import { Typography } from '@mui/material';

export default function GalleryScreen() {
  return (
    <View style={{ minHeight: '100%'}}>
    <Menu location='galeria' />
    <Typography style={{ 
        backgroundColor: theme.colors.primary, 
        color: 'white', 
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 20,
        paddingTop: 10
      }} 
    >GALERIA DE FOTOS</Typography>
    <ScrollView style={{
      backgroundColor: theme.colors.primary                
    }}>

    <ImageList sx={{ width: '100%', margin: '10px' }} cols={innerWidth < 640 ? 2 : 3} rowHeight={300}>
      {itemData.map((item) => (
        <ImageListItem style={{ margin: '2px' }} key={item.img}>
          <img            
            srcSet={`${item.img}?w=200&h=300&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.img}?w=200&h=300&fit=crop&auto=format`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
    </ScrollView>
    <HeaderBotton></HeaderBotton>
    </View>
  );
}

const itemData = [
  {
    img: 'https://i.ibb.co/6bzhjWr/qm1.jpg',
    title: 'Homero Tarrago',
  },
  {
    img: 'https://i.ibb.co/rkvP8Gq/qm2.jpg',
    title: 'Arthur Pires de Freitas',
  },
  {
    img: 'https://i.ibb.co/cxFMXDw/qm3.jpg',
    title: 'João Pedro Alves dos Santos',
  },
  {
    img: 'https://i.ibb.co/tPc0FrN/qm5.jpg',
    title: 'Equipe',
  },
  {
    img: 'https://i.ibb.co/Wc6h3LR/qm4.jpg',
    title: 'Equipe',
  },
  {
    img: 'https://i.ibb.co/Rvm7Xqt/qm7.jpg',
    title: 'Ovinos',
  },
  {
    img: 'https://i.ibb.co/9bBSs3X/qm6.jpg',
    title: 'Ovinos',
  },
  {
    img: 'https://i.ibb.co/Zcc1ncm/Whats-App-Image-2023-09-18-at-15-11-41.jpg',
    title: 'Bovinos',
  },
  {
    img: 'https://i.ibb.co/fMD564Z/Whats-App-Image-2023-09-18-at-15-11-41-1.jpg',
    title: 'Bovinos',
  },
  {
    img: 'https://i.ibb.co/n7dg5SP/Whats-App-Image-2023-09-18-at-15-11-40-2.jpg',
    title: 'Bovinos',
  },
  {
    img: 'https://i.ibb.co/jJSWjpn/Whats-App-Image-2023-09-18-at-15-11-40.jpg',
    title: 'Bovinos',
  },
  {
    img: 'https://i.ibb.co/r0tqdRh/Whats-App-Image-2023-09-18-at-15-11-26.jpg',
    title: 'Bovinos',
  },
  {
    img: 'https://i.ibb.co/wrKr5Pw/gal10.jpg',
    title: 'Bovinos',
  },  
  {
    img: 'https://i.ibb.co/MZ67GgM/gal11.jpg',
    title: 'Bovinos',
  },
  {
    img: 'https://i.ibb.co/dc2Jcdv/gal12.jpg',
    title: 'Bovinos',
  },
];