import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { theme } from '../../../Global/Theme';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Phone from '@mui/icons-material/Phone';
import { Linking } from 'react-native';


function replaceAll(string, search, replace) {
  return string?.split(search).join(replace);
}

function removeCharacter(props) {
  let newString = undefined;
  newString = replaceAll(props, '(', '');
  newString = replaceAll(newString, ')', '');
  newString = replaceAll(newString, ' ', '');
  newString = replaceAll(newString, '.', '');
  console.log(newString);
  return newString;
}

export default function CardContato(props) {
  return (
    <Card sx={{ backgroundColor: theme.colors.primary, width: '300px' }}
      onClick={() => {
        Linking.openURL('https://wa.me/+55' + removeCharacter(props.contact));
      }}
    >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color={theme.colors.secondary} gutterBottom>
          {props.title}
        </Typography>
        <Typography sx={{ fontSize: 14 }} component="div" color={theme.colors.secondary}>
          {props.position}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          style={{ color: theme.colors.secondary }}
          variant="text"
          endIcon={props.WhatsApp ? <WhatsAppIcon /> : <Phone/>}>
          {props.contact}
        </Button>
      </CardActions>
    </Card >
  );
}