import { Text, View, Linking, ScrollView } from "react-native";
import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import { Title, Button, Avatar, List , Card, Paragraph, ActivityIndicator, SegmentedButtons } from "react-native-paper";
import { AuthContext } from "../../../Context/AuthContext/AuthContext";
import { boolean } from "yup";
import axiosInstance from "../../../Services/api/axios";

export default function CardAnuncio(props) {
  const { setState, state } = useContext(AuthContext);
  const [PageImgId, setPageImgId] = React.useState('1');

  const RigthContent = (props) => (
    <Avatar.Image
      {...props}
      style={{ backgroundColor: "white" , marginRight: 10}}
      size={50}
      source={{ uri: "https://i.ibb.co/cg0tzRz/icone-agenda-dourado.png" }}
    />
  );
    
  const getAnuncios = async () => {
		const res = await fetch(axiosInstance + "/AnuncioCategoria/" + props.id);        
		return res.json()            
	};

  const emulateFetch = _ => {
    return new Promise(resolve => {
      resolve([{ data: "ok" }]);
    });
  };

  const handleClick = () => {
    // manually refetch
    refetch();
  };
  
	const { isLoading, error, data, refetch } = useQuery('meusAnuncios', getAnuncios, {
    refetchOnWindowFocus: false,
    enabled: true // disable this query from automatically running
  }); 

  

  if (isLoading)    
    return (
      
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        
        <ActivityIndicator animating={true} color="green" />
        <Text>Buscando Anúncios...</Text>
      </View>
      
  );
    
  if (error)
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <Text>Tivemos problemas.Tente novamente...</Text>
      </View>
  );

  return (
    <ScrollView>      
    <Button style={{ marginLeft: 10, marginRight: 10 }}
            onPress={handleClick} 
            icon="filter"
            textColor="black"
    >
     Aplicar Filtros
    </Button>     
    {      
        data.map((meusAnuncios) => {   
          
          const linkImg = 'https://loterural.s3.us-east-2.amazonaws.com/lote_' + PageImgId + '_img_' + meusAnuncios.id + '.jpg';                      
          var image1 = true
          var image2 = true
          var image3 = true
          var image4 = true
          
          if (meusAnuncios.imagem1 === "S") {
            var image1 = false
          }
          if (meusAnuncios.imagem2 === "S") {
            var image2 = false
          }
          if (meusAnuncios.imagem3 === "S") {
            var image3 = false
          }
          if (meusAnuncios.imagem4 === "S") {
            var image4 = false
          }
          
          return ( 
            <Card style={{ margin: 10 }}>    
            <Card.Title 
              title={"Lote: " + meusAnuncios.lotexx} 
              subtitle={meusAnuncios.deslot}  
              right={RigthContent}/>            
            <Card.Content>
              <Paragraph>{"Categoria: " + meusAnuncios.descricao}</Paragraph>
              <Paragraph>{meusAnuncios.comentario}</Paragraph>
              {meusAnuncios.lanmax &&
              <Paragraph style={{ color: 'red' }}>{"Preço Base: R$" + meusAnuncios.lanmax.toFixed(2)}</Paragraph>
              }
              
            </Card.Content>
            <Card.Cover style={{ marginLeft: 10, 
                                 marginRight: 10 
                              }} 
                              source={{ uri: linkImg }} />
            <SegmentedButtons
              style={{ alignSelf: 'center', 
                       height: 25, 
                       marginTop: 5, 
                       backgroundColor: 'white',
                    }}              
              value={PageImgId}
              density="high"
              onValueChange={setPageImgId}              
              buttons={[
                {
                  
                  value: '1',
                  label: '1',
                  icon: 'image',
                  showSelectedCheck: true,                  
                  disabled: image1,                  
                },
                {
                  value: '2',
                  label: '2',
                  icon: 'image',
                  showSelectedCheck: true,
                  disabled: image2,
                },
                { value: '3', 
                  label: '3',
                  icon: 'image',
                  showSelectedCheck: true,
                  disabled: image3,
                },
                { value: '4', 
                  label: '4',
                  icon: 'image',
                  showSelectedCheck: true ,
                  disabled: image4,
                }
              ]}
            />
           <Card.Actions style={{ margin : 0 }}>
              {meusAnuncios.urlvideo &&
                <Button buttonColor="#182d1c" textColor="white"
                  icon="video">Vídeo
                </Button>            
              }
              <Button buttonColor="#182d1c" textColor="white"
                icon="whatsapp" 
                onPress={() => { 
                          Linking.openURL('https://wa.me/+5555996277432?text=Olá, estou no app @LoteRural e possuo interesse no lote: ' + meusAnuncios.lotexx + ' - ' + meusAnuncios.deslot); 
                        }}>Contato
              </Button>              
            </Card.Actions>            
           </Card>
           
          )          
        }
        )
        
      }          
      
      </ScrollView>
      
  );
  
}

