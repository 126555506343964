import React, { useContext, useState } from "react";
import {
  Alert,
  StyleSheet,
  View,
} from "react-native";
import { Button, Text } from "react-native-paper";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SignInSchema } from "../../utils/YupSchema/SignInSchema";
import TextInputForm from "../../components/TextInputForm/TextInputForm";
import { AuthContext } from "../../Context/AuthContext/AuthContext";
import { useNavigation } from "@react-navigation/native";
import { getUser, Signin } from "../../Services/api/AuthServices";
import { setKey } from "../../Services/SecureStore";
import Menu from "../../components/Gerais/Menu/Menu";
import HeaderBotton from "../../components/HeaderBottom/HeaderBottom";
import { theme } from "../../Global/Theme";
import { ScrollView } from "react-native-gesture-handler";

// const data = {
//   id: 4905,
//   nomexx: "FELIPE ROSLER VINHAS",
//   senhax: "81227330",
//   cpfxxx: "020.235.770-80",
//   cnpjxx: null,
//   emailx: "felipe.vinhas@hotmail.com",
// };

export default function SignUpScreens() {
  const { innerWidth: width, innerHeight: height } = window;
  const [loading, setLoading] = useState(false);
  const { setState, state } = useContext(AuthContext);
  const navigation = useNavigation<any>()
  const handleSaleScreen = () => {
    navigation.navigate("SalesScreen")
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignInSchema),
  });

  const larguraPercent = () => {    
    if (innerWidth <  640) {
      return '100%'
    } else {
      return '50%'
    }
  };

  async function handleUserRegister(values) {
    try {
      const login = await Signin(values.email, values.password);
      const user = await getUser(login.id);
      setState({ user, loggedIn: true, token: login.Token });
      await setKey("user", user);
      await setKey("token", login.Token);
      navigation.navigate(handleSaleScreen)
    } catch (error) {
      Alert.alert("Error ao logar", "Email/CPF ou Senha errado");
    }
  }
  return (
    <View style={{ backgroundColor: theme.colors.primary, flex: 1 }}>
      <Menu location="Login" />        
      <ScrollView
        contentContainerStyle={{
          paddingBottom: 20,
          width: larguraPercent(),
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      >    
        <Text
          style={{ color: 'white', textAlign: "left", marginTop: 10, marginLeft: 10 }}
          variant="titleLarge"
          
        >
          Olá.Para continuar, digite seu usuário e senha
        </Text>
        <TextInputForm
          name="email"
          mode="contained"
          control={control}
          error={errors.email}
          keyboardType="email-address"
          textContentType="emailAddress"
          autoCapitalize="none"
          autoCorrect={false}
          label="E-mail\CPF"
          style={styles.textInput}
        />
        <TextInputForm
          name="password"
          mode="contained"
          control={control}
          error={errors.password}
          textContentType="password"
          secureTextEntry={true}
          label="Senha"
          style={styles.textInput}
        />

        <Button
          mode="contained"
          loading={loading}
          onPress={handleSubmit(handleUserRegister)}
          style={styles.buttonInput}
          buttonColor={theme.colors.tertiary}
        >
          Acessar
        </Button>
        

        <Button
          mode="contained"
          loading={loading}
          style={styles.buttonInput}
          buttonColor={theme.colors.primary}
          onPress={() => navigation.navigate("CheckDocument")}
        >
          Recuperar senha
        </Button>
        </ScrollView>      
      <HeaderBotton />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '50%',
    flex: 1,
    marginTop: -5,
    justifyContent: "flex-start",
  },
  textInput: { marginLeft: 10, marginRight: 10, marginTop: 10 },
  imageTop: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    width: "100%",
    height: 250,
  },
  buttonInput: { marginLeft: 10, marginRight: 10, marginTop: 10 },
  error: {
    color: "red",
    fontSize: 16,
  },
});
