import { Appbar, IconButton } from "react-native-paper";
import ButtonWeb from "../../Cards/ButtomMenu/ButtomMenuWeb";
import { theme } from "../../../Global/Theme";

import ButtonBasesPrincipal from "../../Cards/ButtomMenu/ButtomMenuPrincipal";
import { useNavigation } from "@react-navigation/native";
import { useContext, useState } from "react";
import { AuthContext } from "../../../Context/AuthContext/AuthContext";

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import { Button, DialogActions, Typography } from "@mui/material";
import ButtonMobile from "../../Cards/ButtomMenu/ButtomMenuMobile";
import { Linking } from "react-native";
import styled from "@emotion/styled";

const urlLogo = 'https://i.ibb.co/wgDdNXr/favicon.png';

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 5,
  bottom: 0,
  backgroundSize: '90px 50px',
  backgroundPosition: 'center 40%',
});

export interface SimpleDialogProps {
  open: boolean;
  onClose: (value: string) => void;
  nome: string;
  cpfcgc: string;
  endereco: string;
  bairro: string;
  cidade: string;
  estado: string;
  cep: string;
}

function DadosUsuarioLogado(props: SimpleDialogProps) {
  const { innerWidth: width, innerHeight: height } = window;
  const { onClose, open } = props;

  const handleClose = () => {
    onClose;
  };

  return (
    <Dialog 
      style={{ width: 250, height: 350}}
      onClose={handleClose} 
      open={open}
    >
      <DialogTitle>Meus Dados</DialogTitle>
      <DialogActions>
        <Button onClick={() => handleClose}></Button>
      </DialogActions>
      <Typography>testes</Typography>
    </Dialog>
  );
}
const MenuInicial = (dados) => {
  return (
    <Appbar.Header
      mode="center-aligned"
      style={{ backgroundColor: theme.colors.primary, height: '100px' }}
    >
      
    {
        innerWidth < 640 && (
          <ButtonMobile/>
        )
      }
      {
        innerWidth > 640 && (
          <ButtonWeb/>
        )
      }

    </Appbar.Header >
  )
}

const MenuPrincipal = () => {
  const navigation = useNavigation<any>();
  const [open, setOpen] = useState(false);
  const { setState, state } = useContext(AuthContext);
  const handlePress = () => {
    navigation.navigate("Login")
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);

  };
  return (
    <>
      <Appbar style={{ height: '3px', width: '100%', backgroundColor: theme.colors.tertiary }} children={""} />
      <Appbar
        style={{
          height: '40px',
          width: '100%',
          backgroundColor: theme.colors.primary,
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}>
        <IconButton
          style={{ paddingTop: 10 }}
          icon="youtube"
          iconColor={theme.colors.tertiary}
          size={25}
          onPress={() => {
            Linking.openURL('https://www.youtube.com/@AGENDAREMATES');
          }}
        />
        <IconButton
          style={{ paddingTop: 10 }}
          icon="facebook"
          iconColor={theme.colors.tertiary}
          size={25}
          onPress={() => {
            Linking.openURL('https://www.facebook.com/agendarematesalegrete/?locale=pt_BR');
          }}
          
        />
        <IconButton
          style={{ paddingTop: 10 }}
          icon="whatsapp"
          iconColor={theme.colors.tertiary}
          size={25}
          onPress={() => {
            Linking.openURL('https://wa.me/+5555996138016');
          }}
        />
        <IconButton
          style={{ paddingTop: 10 }}
          icon="instagram"
          iconColor={theme.colors.tertiary}
          size={25}
          onPress={() => {
            Linking.openURL('https://www.instagram.com/agendaremates/');
          }}
        />
        <IconButton
          style={{ paddingTop: 10 }}
          icon="login"
          iconColor={theme.colors.tertiary}
          size={25}
          onPress={!state.loggedIn ? handlePress : handleClickOpen}
        />
        <DadosUsuarioLogado
          open={open}
          onClose={handleClose}
          nome=""
          bairro=""
          cep=""
          cidade=""
          cpfcgc=""
          endereco=""
          estado=""
        />

      </Appbar>

      < Appbar.Header
        mode="center-aligned"
        style={{
          backgroundColor: theme.colors.secondary,
          height: '70px',
          width: '100%',
          justifyContent: innerWidth < 640 ? ("flex-start") : ("center"),
          alignItems: "center"
        }}
      >
        <ButtonBasesPrincipal tag={location}></ButtonBasesPrincipal>
        {innerWidth < 640 ??  
        <ImageSrc 
          style={{
            backgroundImage: `url(${urlLogo})`,
            backgroundRepeat: 'no-repeat',
            width: '100px',          
            marginLeft: 'auto',
            marginRight: '10px'
          }} 
        />        
        }
      </Appbar.Header >

        

      
      
    </>

  )
}

export default function Menu(props) {
  const location = props.location;
  return (
    <>
      {
        location === "Home" && (
          <MenuInicial/>
        )
      }
      {
        location != "Home" && (
          <MenuPrincipal/>
        )
      }
    </>

  )
}

