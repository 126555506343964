import * as yup from "yup";

export const SignUpSchema = yup.object().shape({
  email: yup
    .string()
    .email("O e-mail é inválido!")
    .required("O e-mail é obrigatório!"),
  senha: yup.string().required("A senha é obrigatória!"),
  cpfcgc: yup.string().required("CPF é obrigatório").min(11).max(11),
  cel1: yup.string().required("Celular é obrigatório"),
  cidade: yup.string().required("Cidade é obrigatório"),
  refer1: yup.string().required("referencia é obrigatório"),
  nome: yup.string().required("nome é obrigatório"),
});
